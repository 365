import { IntContent } from '../../components/Content/IntContent'

export const Help = () =>
    IntContent({
        intl: {
            en: '4pgJiPBseIDL0zBIevuKn1',
            es: '4Mqfuxfn14juIcALHnzdmU',
        },
        titleStyles: {
            marginTop: '0',
        },
    })
