import { ReactNode } from 'react'
import logo from '../assets/egg_no_shadow.svg'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { Link } from 'react-router-dom'

export const ToHeader = ({ left, center, right }: IProps) => {
    return (
        <StyledHeaderActivation>
            <div className="left">{left}</div>
            <div className="center">
                {center ?? (
                    <Link to={'/redirect'}>
                        <img src={logo} width="20" height="28" alt="" />
                    </Link>
                )}
            </div>
            <div className="right">{right}</div>
        </StyledHeaderActivation>
    )
}

const StyledHeaderActivation = styled.header`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: ${defaultTheme.color.white};
    place-items: center;
    min-height: 50px;
    padding: 0 2em;
    .left {
        justify-self: start;
    }
    .right {
        justify-self: end;
        & select {
            border: none;
            width: 180px;
            padding: 10px;
            box-shadow: none;
            font-size: small;
            background-size: 2em;
        }
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: min-content auto 1fr;
        gap: 1em;
    }
`

type IProps = {
    left?: ReactNode
    center?: ReactNode
    right?: ReactNode
}
