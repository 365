import styled from 'styled-components'
import { PrimaryBtnBlue, PrimaryBtnOutline } from '../Buttons/PrimaryBtn'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultTheme } from '../../styles/themes/defaultTheme'

export const CancelButton = styled(PrimaryBtnOutline)`
    border-color: transparent;
    margin: 0;
    transition: background-color 0.3s, color 0.3s;
    :hover:not(:disabled),
    :disabled,
    :hover {
        background-color: #eaeaea;
        border-radius: ${defaultTheme.borderRadius};
        box-shadow: 0px 0px 30px #0000000d;
        color: #00a3ff;
    }
`

export const ConfirmButton = styled(PrimaryBtnBlue)`
    transition: background-color 0.3s, color 0.3s;
`

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`

export const Icon = styled(FontAwesomeIcon)`
    color: #003750;
    font-size: 70px;
    font-weight: 300;
    line-height: 84px;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 50px;
    }
`

export const ConfirmationModalContainer = styled.div`
    background: #fff;
    border-radius: 20px;
    border: 1px solid #ebebeb;
    display: grid;
    gap: 45px;
    height: 400px;
    padding: 70px 57px 53px;
    place-items: center;
    width: 400px;
`

export const Title = styled.h4`
    color: #1f394c;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    margin: 0;
    text-align: center;
`
