import { createContext, Dispatch, useState } from 'react'
import { LxpInfoInterface } from '../interfaces/user'
import { useUser } from '../hooks/queries/useUser'
import { OrganizationBranding } from '../infrastructure/api-activation'
import { useOrganization } from '../hooks/useOrganization'

const defaultValues = {
    user: undefined,
    setUser: () => null,
    lxpInfo: null,
    setLxpInfo: () => null,
    organization: undefined,
}

export const UserContext = createContext<ContextType>(defaultValues)

type ContextType = {
    user?: {
        email: string
        displayName: string
        lastname: string
        name: string
        profileId: string
        urlImage: string
        userId: string
        preOnboardingComplete: boolean
        interestQuestionnaireComplete: boolean
    }
    setUser: Dispatch<ContextType['user'] | null>
    lxpInfo: LxpInfoInterface | null
    setLxpInfo: Dispatch<LxpInfoInterface | null>
    organization?: OrganizationBranding
}

type IProps = {
    children: React.ReactNode
}

const UserContextProvider = ({ children }: IProps) => {
    const browserUser = window.localStorage.getItem('lxp-user') || ''
    const parsedUser = browserUser !== '' ? JSON.parse(browserUser) : null
    const [, setUser] = useState<ContextType['user'] | null>(parsedUser)
    const [lxpInfo, setLxpInfo] = useState<LxpInfoInterface | null>(null)

    const { user } = useUser()
    const {branding} = useOrganization()

    const value = {
        user: user as ContextType['user'],
        setUser,
        lxpInfo,
        setLxpInfo,
        branding,
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
