import styled, { css } from 'styled-components'

import { DeckType } from '../../interfaces/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { StyledCard } from '../Card'
import discord_icon from '../../assets/discord_icon_blue.svg'
import { useNavigate } from 'react-router-dom'

type IProps = {
    icon: IconProp
    title: string
    desc: string
    slug?: DeckType
    contentId?: string
    onActionClick?: () => void
}

const DynamicCard = ({ icon, title, desc, slug, onActionClick }: IProps) => {
    const navigate = useNavigate()

    return (
        <StyledDynamicCard>
            {slug === DeckType.Discord ? (
                <img src={discord_icon} height={30} alt="Discord Icon" />
            ) : (
                <FontAwesomeIcon className="icon" icon={icon as IconProp} />
            )}
            <h3>{title}</h3>
            <p>{desc}</p>
            <ContentActions moreOneElement={slug === DeckType.Discord}>
                {slug === DeckType.Discord && (
                    <span className="link">
                        <button
                            style={{ padding: 0 }}
                            onClick={() => {
                                navigate('/content/info-discord')
                            }}
                        >
                            Tutorial
                        </button>
                    </span>
                )}

                {slug && (
                    <span className="link">
                        <button onClick={() => onActionClick?.()}>
                            {slug === DeckType.Discord ? 'Ingresar' : 'Ver ahora'}
                            <FontAwesomeIcon
                                style={{ marginLeft: '5px', color: '00A3FF' }}
                                icon={['fas', 'arrow-right']}
                            />
                        </button>
                    </span>
                )}
            </ContentActions>
        </StyledDynamicCard>
    )
}

export default DynamicCard

const StyledDynamicCard = styled(StyledCard)`
    color: ${props => props.theme.color.primary};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    flex: 1;
    border-radius: 20px;
    border: none;
    @media screen and (min-height: 600px) {
        min-height: 170px;
    }
    .icon {
        color: ${props => props.theme.color.electricBlue};
        font-size: 30px;
    }
    h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        flex: 1;
        color: ${props => props.theme.color.copys} !important;
    }
    a {
        font-size: 14px;
        text-align: right;
        font-weight: 600;
    }
    .link {
        button {
            background: none;
            border: none;
            color: ${props => props.theme.color.primary};
            font-weight: bold;
            text-align: right;
            width: 100%;
            text-decoration: none;
            font-size: 13px;
            font-weight: 600;
            /* text-transform: lowercase; */
            cursor: pointer;
            &:hover {
                color: ${props => props.theme.color.electricBlue};
            }
        }
    }
`

const ContentActions = styled.div<{ moreOneElement: boolean }>`
    width: 100%;
    text-align: right;
    ${prop =>
        prop.moreOneElement &&
        css`
            display: flex;
            justify-content: space-between;
        `}
`
