import { useNavigate, useSearchParams } from 'react-router-dom'
import { loginFromAdmin } from '../../../infrastructure/api-widget'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ReactNode, useState } from 'react'

export const LoginFromAdmin = () => {
    const { t } = useTranslation('signIn')

    const [msg, setMsg] = useState('Loading...')
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    const code = searchParams.get('code')

    const queryKey = ['Login from Admin', code]

    useQuery({
        queryKey,
        queryFn: async () => {
            try {
                const data = await loginFromAdmin(`${code}`)
                if (!data.data.token) throw new Error('No token in response')
                setMsg('Success')
                navigate('/')
                return data
            } catch (err: any) {
                setMsg(err.response.data.message ?? 'Invalid code')
            }
        },
        enabled: !!code,
        retry: false,
        staleTime: 0,
    })

    return <Center>{t(msg)}</Center>
}

const Center = ({ children }: { children: ReactNode }) => (
    <div style={{ display: 'grid', placeItems: 'center', textAlign: 'center' }}>{children}</div>
)
