import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react'
import { RankingParams, UserRankingParams } from '../../../infrastructure/api-ranking'
import { useNavigate, useParams } from 'react-router-dom'

import { Input } from '../../../components/FormElements/Input'
import { LoadingSpinner } from '../../static/LoadingPage'
import { RankingListCard } from './RankingListCard'
import { RankingUserCard } from './RankingUserCard'
import { Select } from '../../../components/FormElements/Select'
import { Title } from '../../../components/Title'
import styled from 'styled-components'
import { useCourses } from '../hooks/useCourses'
import { useRanking } from '../hooks/useRanking'
import { useTranslation } from 'react-i18next'
import { useUserRanking } from '../hooks/useUserRanking'

export const RankingView = () => {
    const { t } = useTranslation('ranking')
    const { course = '' } = useParams()
    const { courses, isLoading: isLoadingCourses } = useCourses()
    const navigate = useNavigate()

    const defaultProps = useMemo(
        () => ({
            course: course || courses?.[0]?._courseId || '',
            type: 'general',
        }),
        [course, courses]
    )

    const [userRankingFilters, setUserRankingFilters] = useState<UserRankingParams>(defaultProps)
    const [rankingListFilters, setRankingListFilters] = useState<RankingParams>(defaultProps)

    const [isSearchActive, setIsSearchActive] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const { userRanking, isLoading: isLoadingUserRanking } = useUserRanking(
        userRankingFilters,
        courses
    )
    const { ranking, isLoading, isFetching, fetchNextPage, hasNextPage, columnsWidth } =
        useRanking(rankingListFilters)

    const handleSelect = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
        setRankingListFilters({ course: value })
        setUserRankingFilters({ course: value })
        setSearchValue('')
        setIsSearchActive(false)
    }

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            setRankingListFilters({ course: rankingListFilters.course, name: searchValue })
            setIsSearchActive(false)
        }
    }

    const handleSeeMore = () => hasNextPage && fetchNextPage()

    useEffect(() => {
        if (rankingListFilters.course !== '') {
            rankingListFilters.course !== 'undefined'
                ? navigate(`/ranking/${rankingListFilters.course}`)
                : navigate('/ranking')
        } else {
            navigate('/ranking')
        }
    }, [rankingListFilters.course, navigate])

    const showMoreButton =
        rankingListFilters.page !== 'me' ? (
            <button className="text" onClick={handleSeeMore}>
                {t('Show more')}
            </button>
        ) : null

    return (
        <RankingStyles className="redesign max-w-1310">
            <div className="ranking-title">
                <Title title={t('Ranking')} subTitle="" size="large" />
            </div>
            <div className="ranking-bar">
                <div className="ranking-select bar-dark">
                    <Select
                        id="rankingId"
                        name="rankingId"
                        className="capitalize"
                        value={rankingListFilters.course}
                        onChange={handleSelect}
                        disabled={!courses}
                    >
                        {courses?.map(({ courseName, _courseId }) => (
                            <option key={_courseId} value={_courseId}>
                                {courseName}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="ranking-search">
                    <Input
                        id="search"
                        name="search"
                        type="search"
                        placeholder={t('Search by name')}
                        iconPrefix="fal"
                        icon="search"
                        onKeyDown={handleKeyPress}
                        onChange={({ target: { value } }) => setSearchValue(value)}
                        value={searchValue}
                    />
                </div>
            </div>
            {!isLoadingUserRanking && !isLoadingCourses && (
                <RankingUserCard
                    userRanking={userRanking}
                    searchValue={searchValue}
                    setRankingListFilters={setRankingListFilters}
                    isActive={[isSearchActive, setIsSearchActive]}
                />
            )}

            {isLoadingUserRanking || isLoading ? (
                <div className="loading-page">
                    <LoadingSpinner fontSize="2em" />
                </div>
            ) : (
                ranking && (
                    <>
                        <RankingListCard
                            ranking={ranking}
                            columnsWidth={columnsWidth}
                            userPosition={userRanking?.position}
                        />
                        <div className="ranking-button align-center">
                            {hasNextPage && (isFetching ? <LoadingSpinner /> : showMoreButton)}
                        </div>
                    </>
                )
            )}
        </RankingStyles>
    )
}

const RankingStyles = styled.div`
    padding-bottom: 3em;
    .ranking-title {
        margin-bottom: 44px;
    }
    .ranking-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1em;
            > div {
                width: 100%;
            }
        }
    }
    .ranking-select {
        width: 50%;
        @media screen and (max-width: 768px) {
            padding-right: 0;
            border-right: none;
        }
    }

    .ranking-select select {
        padding: 1em 1.5em;
        font-size: 1rem;
        @media screen and (max-width: 768px) {
            padding: 1em 0.75em;
        }
    }

    .ranking-search {
        width: 50%;
        padding-left: 22px;
        .input-icon {
            width: 22px;
            height: 22px;
        }
        .input-field {
            height: 50px;
            ::-webkit-search-cancel-button {
                -webkit-appearance: none;
                height: 22px;
                width: 22px;
                margin-left: 0.4em;
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' stroke='rgb%280,55,80%29' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'/></svg>");
                background-repeat: no-repeat;
                cursor: pointer;
            }
        }
        @media screen and (max-width: 768px) {
            padding-left: 0;
            .input-icon {
                width: 18px;
                height: 18px;
            }
            .input-field {
                padding: 7px 7px 7px 30px;
            }
        }
    }
    .ranking-button {
        margin-top: 3em;
    }
    .loading-page {
        height: 40vh;
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
    }

    @media (max-width: 768px) {
        & .ranking-card-container.ranking-container {
            & > h1.ranking-card-position,
            & > h1.ranking-card-pulses {
                font-size: 1.2em !important;
            }
            & > p.ranking-card-display-name {
                font-size: 1em !important;
            }
            & > img.ranking-card-pulse-icon {
                width: 1.5em;
                padding-left: 0.5em;
            }
        }
    }
`
