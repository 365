import { useQuery } from '@tanstack/react-query'
import { useToken } from '../../../hooks/useToken'
import { getActiveContent } from '../../../infrastructure/api-widget'

export const useActiveCourse = () => {
    const { _classId: _courseId } = useToken()
    const queryKey = ['Course', 'Active', _courseId]
    const queryFn = () => getActiveContent(`${_courseId}`)

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: !!_courseId,
    })

    const activeCourse = data
        ? {
              ...data,
              _courseId,
          }
        : undefined

    return { activeCourse, ...rest }
}
