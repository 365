import { getCohorts } from '../api-marketplace'
import { useQuery } from '@tanstack/react-query'

export const useCohorts = ({ slug, courseId }: { slug?: string; courseId?: string }) => {
    const queryKey = ['Course', slug, 'Cohorts', courseId]
    const { data: cohorts, ...rest } = useQuery({
        queryKey,
        queryFn: () => getCohorts(`${courseId}`),
        enabled: !!courseId,
    })

    const getCohortById = (cohortId?: string) => cohorts?.find(({ id }) => id === cohortId) ?? null

    return { cohorts, getCohortById, ...rest }
}
