import { useQuery } from '@tanstack/react-query'
import { getContentStep } from '../../../infrastructure/api-widget'
import { GACategory, SendGAEvent } from '../../../helpers/GAEvents'

export const useContentStep = (stepId?: string) => {
    const queryKey = ['Content', 'Step', stepId]

    const { data: step, ...rest } = useQuery({
        queryKey,
        queryFn: async () => {
            try {
                SendGAEvent(GACategory.CONTENT, 'Step seen', stepId)
                return getContentStep(`${stepId}`)
            } catch (error) {}
        },
        enabled: !!stepId,
    })

    return { step, ...rest }
}
