const { REACT_APP_ENV = 'development' } = process.env

export const isProduction = REACT_APP_ENV === 'production'
export const isDevelopment = REACT_APP_ENV === 'development'

export enum DeckType {
    Faq = 'faq',
    Methodology = 'methodology',
    Info = 'info',
    Discord = 'discord',
}
