import Axios from 'axios'

const { REACT_APP_URL_WEBSOCKET } = process.env

if (!REACT_APP_URL_WEBSOCKET)
    console.error('REACT_APP_URL_WEBSOCKET to be declared on your system environments variables')

const baseURL = `${REACT_APP_URL_WEBSOCKET}/api`

export const ApiSocket = Axios.create({
    baseURL,
})

export const getRoom = (roomId: string) => ApiSocket.get(`/rooms/${roomId}`)
