import { useQueryClient } from '@tanstack/react-query'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Country } from '../../../components/FormElements/Country'
import { Input } from '../../../components/FormElements/Input'
import {
    CountryCode,
    PhoneNumber,
    isValidPhone,
} from '../../../components/FormElements/PhoneNumber'
import { validateCI } from '../../../helpers/ciValidation'
import { useCountries } from '../../../hooks/queries/useCountries'
import { useLocalization } from '../../../hooks/queries/useLocalization'
import { DefaultUserDTO } from '../../../interfaces/api-activation'
import { useUpdateUser } from '../../../hooks/queries/useUpdateUser'
import LoadingPage from '../../static/LoadingPage'
import { SignUpStyle } from './SignUpStep1'
import { useUser } from '../../../hooks/queries/useUser'

export const SignUpStep2 = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { t } = useTranslation('signUp')
    const { localization } = useLocalization()
    const { countries } = useCountries()
    const { user, isLoading } = useUser()
    const { updateAsync, api_error, isUpdating } = useUpdateUser()
    const [changes, setChanges] = useState<Partial<DefaultUserDTO & typeof user>>({})
    const { countryId, name, lastname, phone, referrer, dni } = changes ?? {}

    const [defaultCountry, setDefaultCountry] = useState<CountryCode>(
        localization?.country_code as CountryCode
    )

    const DNIRequiredCountries = ['Uruguay']

    const isDNIRequired = countries?.some(
        ({ name, id }) => DNIRequiredCountries.includes(name) && id === (countryId ?? 0)
    )

    const sanitizedReferrer = referrer?.toLowerCase().trim()

    useEffect(() => {
        if (!user) return
        user && setChanges(user)
    }, [user])

    // Set user country
    useEffect(() => {
        if (countryId) return
        const updateCountryId = (id: number) => {
            setChanges(prev => (prev ? { ...prev, countryId: id } : prev))
        }

        const findCountryId = () => {
            if (sanitizedReferrer === 'inefop') {
                return countries?.find(({ name }) => name === 'Uruguay')?.id ?? ''
            } else {
                return (
                    countries?.find(({ iso3 }) => iso3 === localization?.country_code_iso3)?.id ??
                    ''
                )
            }
        }

        const countryIdToSet = findCountryId()
        if (countryIdToSet) {
            updateCountryId(countryIdToSet)
        }
    }, [
        countryId,
        countries,
        localization?.country_code_iso3,
        localization?.country_code,
        sanitizedReferrer,
    ])

    useEffect(() => {
        if (!countryId) return
        const country = countries?.find(({ id }) => id === countryId)
        country && setDefaultCountry(country.iso2 as CountryCode)
    }, [countryId, countries])

    const uyCountryId = countries?.find(({ name }) => name === 'Uruguay')?.id

    const onChange = ({
        target: { name, value },
    }: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        if (name === 'referrer' && value.toLowerCase() === 'inefop')
            setChanges(prev => ({ ...prev, countryId: uyCountryId }))

        setChanges(prev => ({ ...prev, [name]: name === 'countryId' ? +value : value }))
    }

    const isCompleted =
        countryId &&
        name &&
        lastname &&
        phone &&
        (!isDNIRequired || dni) &&
        (sanitizedReferrer !== 'inefop' || countryId === uyCountryId)

    const onSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        if (!isCompleted) return
        if (referrer?.length === 0) delete changes.referrer
        await updateAsync(changes)
        queryClient.invalidateQueries().then(() => navigate('/'))
    }

    if (isLoading || isUpdating) return <LoadingPage />

    return (
        <SignUpStyle>
            <strong className="blue">
                {t('Step {{ step }} of {{ steps }}', {
                    step: 2,
                    steps: 2,
                })}
            </strong>
            <h1 className="title">{t('Personal information')}</h1>
            <p className="light">
                {t(
                    'Now you are part of the network that is promoting your professional development through cooperation.'
                )}
            </p>
            <br />

            <form onSubmit={onSubmit}>
                <Input
                    {...{
                        label: t('Name'),
                        id: 'name',
                        name: 'name',
                        type: 'text',
                        autoComplete: 'given-name',
                        value: name ?? '',
                        onChange,
                        api_error,
                    }}
                />
                <br />

                <Input
                    {...{
                        label: t('Surname'),
                        id: 'lastname',
                        name: 'lastname',
                        type: 'text',
                        autoComplete: 'family-name',
                        value: lastname ?? '',
                        onChange,
                        api_error,
                    }}
                />
                <br />

                <Country
                    {...{
                        value: countryId ?? '',
                        onChange,
                        api_error,
                        disabled: sanitizedReferrer === 'inefop',
                    }}
                />
                {isDNIRequired && (
                    <>
                        <br />
                        <Input
                            {...{
                                label: t('C.I.'),
                                id: 'dni',
                                name: 'dni',
                                type: 'string',
                                value: dni ?? '',
                                onChange,
                                disabled: !!user?.dni,
                                api_error,
                                maxLength: 8,
                                error: dni && !validateCI(dni) ? t('Invalid C.I.') : undefined,
                            }}
                        />
                    </>
                )}
                <br />
                <PhoneNumber
                    {...{
                        label: t('What is your phone number?'),
                        defaultCountry,
                        value: (phone as any) ?? undefined,
                        onChange: (phone: any) =>
                            setChanges(prev => (prev ? { ...prev, phone: phone ?? null } : prev)),
                        error:
                            phone && !isValidPhone(phone ?? '')
                                ? t('Invalid phone number')
                                : undefined,
                    }}
                />
                <br />
                <Input
                    {...{
                        label: t('Do you have any code?'),
                        id: 'referrer',
                        name: 'referrer',
                        type: 'text',
                        autoComplete: 'off',
                        value: referrer ?? '',
                        onChange,
                        disabled: !!user?.referrer,
                        api_error,
                    }}
                />
                <br />
                <button
                    className="primary"
                    children={t('Continue')}
                    style={{ width: '100%', margin: '0 auto' }}
                    disabled={!isCompleted}
                />
            </form>
        </SignUpStyle>
    )
}
