import { useTranslation } from 'react-i18next'

import { SelectProps, Select } from './Select'

export const Gender = (props?: SelectProps) => {
    const { t } = useTranslation('signUp')

    return (
        <Select
            {...{ label: t('Gender'), id: 'gender', name: 'gender', autoComplete: 'sex', ...props }}
        >
            <option value="" children={t('Select a gender')} disabled />
            <option value={'male'} children={t('Man')} />
            <option value={'female'} children={t('Woman')} />
            <option value={'unspecified'} children={t('Do not specify')} />
        </Select>
    )
}
