import { LanguageSelector } from '../../components/LanguageSelector'
import { BackToHomeButton } from '../../components/Buttons/BackToHomeButton'
import { HeaderLayout } from '../../layouts/HeaderLayout'
import { Outlet, useLocation } from 'react-router-dom'

export const RegisterLayout = () => {
    const { pathname } = useLocation()
    const includePaths = ['/registro/1']
    const isInclude = includePaths.every(path => pathname.includes(path))
    return (
        <HeaderLayout
            header={{
                left: isInclude ? <BackToHomeButton /> : null,
                right: <LanguageSelector label="" />,
            }}
            main={<Outlet />}
        />
    )
}
