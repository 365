import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useRef } from 'react'
import { Socket, io } from 'socket.io-client'

import { useActions } from '../hooks/useActions'
import { useToken } from '../hooks/useToken'
import { useTranslation } from 'react-i18next'
import { useUIContext } from './UIContext'

const server = process.env.REACT_APP_URL_WEBSOCKET
const debug = window.location.hostname === 'localhost'

const SocketsContext = createContext<ContextTypes | null>(null)
SocketsContext.displayName = 'SocketContext'

export const SocketProvider = ({ children }: PropsWithChildren) => {
    const { token } = useToken()
    const { t } = useTranslation('App')
    const { showToast, showError } = useUIContext()

    const { actionDispatcher } = useActions()
    const socket = useRef<Socket | null>(null)

    const emit = (eventName: string, payload?: Record<string, unknown>) => {
        if (!socket.current) throw new Error('❗ Socket connection not available.')
        return socket.current.emit(eventName, payload)
    }
    const volatileEmit = (eventName: string, payload?: Record<string, unknown>) => {
        if (!socket.current) throw new Error('❗ Socket connection not available.')
        return socket.current.volatile.emit(eventName, payload)
    }

    const connect = useCallback(
        (token: string) => {
            if (!server) {
                debug && showError(t('❗ Socket server not available'))
                return
            }

            socket.current = io(server, {
                auth: { token },
                query: {
                    token,
                },
            })

            socket.current.on(
                'connect',
                () => debug && showToast(t('Connected to socket server'), { type: 'success' })
            )

            socket.current.on(
                'disconnect',
                () => debug && showError(t('Disconnected from socket server'))
            )

            socket.current.onAny((event, payload) => actionDispatcher({ event, payload }))
        },
        [actionDispatcher, showError, showToast, t]
    )

    /* Manage connection */
    useEffect(() => {
        if (!token && process.env.NODE_ENV !== 'test') return

        // Listen for session storage changes
        window.onstorage = ({ key, newValue: token }) => {
            if (key !== 'token') return

            if (!token) return socket.current?.disconnect()

            connect(token)
        }

        connect(token)

        return () => {
            socket.current?.disconnect()
        }
    }, [connect, token])
    return (
        <SocketsContext.Provider value={{ emit, volatileEmit }}>{children}</SocketsContext.Provider>
    )
}

export const useSocket = () => {
    const context = useContext(SocketsContext)
    if (context === undefined) {
        throw new Error('useSocket must be used within a SocketProvider')
    }
    return context
}

interface ContextTypes {
    // eslint-disable-next-line no-unused-vars
    emit: (eventName: string, payload?: Record<string, unknown>) => Socket
    // eslint-disable-next-line no-unused-vars
    volatileEmit: (eventName: string, payload?: Record<string, unknown>) => Socket
}
