import { Certificate, useCertificates } from '../../hooks/useCertificates'

import BtnSquare from '../../components/Buttons/BtnSquare'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { dataURItoBlob } from '../../helpers/formatters'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useState } from 'react'

const CertificateItem = (certificate: Certificate) => {
    const { getLinkedInCertURL, getCertificatePdf } = useCertificates()
    const [isDownloading, setIsDownloading] = useState(false)
    const [isLinkedIn, setIsLinkedIn] = useState(false)

    const { title, updatedAt } = certificate

    const onDownload = async () => {
        setIsDownloading(true)
        getCertificatePdf(certificate)
            .then(download)
            .finally(() => setIsDownloading(false))
    }

    const onLinkedIn = () => {
        setIsLinkedIn(true)
        getLinkedInCertURL(certificate)
            .then(window.open)
            .finally(() => setIsLinkedIn(false))
    }

    return (
        <CertificateItemStyled>
            <div className="icon">
                <FontAwesomeIcon icon={['fal', 'file-certificate']} />
            </div>
            <div className="certificate">{title}</div>
            <div className="date">{dayjs(updatedAt).locale('es').format('LL')}</div>
            <div className="share">
                <BtnSquare onClick={onLinkedIn} mode="light">
                    {isLinkedIn ? (
                        <FontAwesomeIcon icon={['fal', 'spinner']} spin />
                    ) : (
                        <FontAwesomeIcon icon={['fab', 'linkedin']} />
                    )}
                </BtnSquare>
                <BtnSquare disabled={isDownloading} onClick={onDownload} mode="light">
                    {isDownloading ? (
                        <FontAwesomeIcon icon={['fal', 'spinner']} spin />
                    ) : (
                        <FontAwesomeIcon icon={['fal', 'circle-arrow-down']} />
                    )}
                </BtnSquare>
            </div>
        </CertificateItemStyled>
    )
}

export default CertificateItem

const CertificateItemStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
    padding-bottom: 20px;
    &:not(:last-child) {
        border-bottom: ${props => `1px solid ${props.theme.color.lightGray}`};
    }
    .icon {
        font-size: 24px;
        flex: 0.1;
        text-align: center;
    }
    .course {
    }
    .certificate {
        flex: 1;
        font-size: 15px;
        padding: 0 10px;
        text-transform: capitalize;
    }
    .date {
        font-size: 15px;
        padding: 0 10px;
    }
    .share {
        display: flex;
    }
`

const download = async (uri: string) => {
    try {
        const blob = dataURItoBlob(uri)
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'certificate.pdf')
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    } catch (error) {
        console.log('Error fetching certificate')
    }
}
