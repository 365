import styled from 'styled-components'
import { Icon } from '../Icon'

const SocialBlock = () => {
    return (
        <Social data-testid="social">
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/eggcooperation/">
                <Icon icon={['fab', 'instagram']} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/c/EggCooperation">
                <Icon icon={['fab', 'youtube']} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/EggCooperation">
                <Icon icon={['fab', 'facebook']} />
            </a>
            <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/eggcooperation/"
            >
                <Icon icon={['fab', 'linkedin']} />
            </a>
        </Social>
    )
}

export default SocialBlock

const Social = styled.nav`
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: initial;
`
