import { Icon } from './ConfirmationModalStyles'
import { GACategory, SendGAEvent } from '../../helpers/GAEvents'
import { useTranslation } from 'react-i18next'
import { Modal } from './Modal'
import { useUIContext } from '../../context/UIContext'
import styled from 'styled-components'

export const LogoutModal = ({ showLogoutModal, setShowLogoutModal }: any) => {
    const { t } = useTranslation('home')
    const { modalClose } = useUIContext()

    const logout = () => {
        SendGAEvent(GACategory.EXIT, `Logout`)
        window.location.href = '/logout'
    }

    return (
        <Modal>
            <Styles>
                <Icon icon={['fal', 'arrow-right-from-bracket']} />
                <h2 className="logout-modal-title">{t('Do you want to log out?')}</h2>

                <div className="buttons">
                    <button className="outlined" onClick={modalClose}>
                        {t('Cancel')}
                    </button>

                    <button className="primary" onClick={logout}>
                        {t('Yes, log out')}
                    </button>
                </div>
            </Styles>
        </Modal>
    )
}

const Styles = styled.div`
    display: grid;
    place-items: center;
    gap: 4em;
    padding: 3em;
    @media screen and (max-width: 768px) {
        gap: 2em;
        padding: 2em 1em;
        .logout-modal-title {
            font-size: 1.5em;
        }
    }
`
