import ReactGA from 'react-ga4'
import { DeckType } from '../interfaces/constants'

export enum GACategory {
    LOGIN = 'Login',
    ENROLLMENT = 'Enrollment',
    SHORTCUT = 'Shortcut',
    CONTENT = 'Content',
    ERROR = 'Error',
    EXIT = 'Exit',
    MAGIC_LINK = 'Magic Link',
    TYC = 'T&C',
    ADS = 'Ads',
    DISCORD = 'Discord',
    CERTIFICATION = 'Certification',
    PREONBOARDING = 'Preonboarding',
    REFERRER = 'Referrer',
}

type Dimensions = {
    origin?: string
    hq?: string
    course?: string
    goal?: string
    profile?: string
    role?: string
    contentDay?: string
    contentDescription?: string
    module?: string
    section?: string
    career?: string
}

export const SendGAEvent = (category: GACategory, action: string, label?: string) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label || undefined,
    })
}

export const setCorrectGAEventName = (page: DeckType) => {
    switch (page) {
        case DeckType.Methodology:
            return 'Metodology'
        case DeckType.Faq:
            return 'Tutorial'
        case DeckType.Info:
            return 'Information'
    }
}

export const trackPageView = (route: string) => {
    // ReactGA.pageview(route)
}

export const SetGADimensions = (dimensions: Dimensions) => {
    ReactGA.set({
        dimension1: 'lxp',
        dimension2: dimensions.hq,
        dimension3: dimensions.course,
        dimension4: dimensions.goal,
        dimension5: dimensions.profile,
        dimension7: 'student',
        dimension8: dimensions.contentDay,
        dimension9: dimensions.contentDescription,
        dimension10: dimensions.module,
        dimension11: dimensions.section,
        dimension12: dimensions.career,
    })
}
