import { useQuery } from '@tanstack/react-query'
import { useUser } from '../../../hooks/queries/useUser'
import { getEcosystem } from '../api-marketplace'

export const useEcosystem = ({ lang }: { lang?: string }) => {
    const { user } = useUser()

    const queryKey = ['Ecosystem', lang ?? 'All']
    const queryFn = () => getEcosystem(lang)

    const { data: ecosystem, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: user?.hideMarketplace === false,
    })

    const allTags = Array.from(
        ecosystem?.courses
            ? new Set(
                  ecosystem.courses
                      .map(({ tags }) => tags)
                      .flat()
                      .map(({ name }) => name)
                      .sort()
              )
            : []
    )

    return { ecosystem, allTags, ...rest }
}
