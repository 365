import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useUIContext } from '../../context/UIContext'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { Icon } from '../Icon'

export const Modal = ({ children }: PropsWithChildren) => {
    const { modalClose } = useUIContext()

    return (
        <ModalStyles className="redesign">
            <button className="icon outlined close" onClick={modalClose}>
                <Icon icon={['far', 'times']} size="xl" />
            </button>
            <div className="content">{children}</div>
        </ModalStyles>
    )
}
const ModalStyles = styled.div`
    position: relative;
    background-color: white;
    border-radius: 16px;
    margin: 3em;
    padding: 2.5em;
    color: ${defaultTheme.color.primaryOpacity};
    & .close.icon,
    & .close.icon:hover {
        position: absolute;
        top: 0.7em;
        right: 0.7em;
        padding: 0 0.2em !important;
        min-width: 1em !important;
    }
`
