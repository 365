import styled from 'styled-components'
import { defaultTheme } from '../../../styles/themes/defaultTheme'

export const CommonRankingCardStyle = styled.div`
    padding: 20px 40px;
    border: 1px solid ${defaultTheme.color.lightGray};
    border-radius: ${defaultTheme.borderRadius};
    .ranking-card-container {
        display: grid;
        align-items: center;
        gap: 1em;

        .ranking-card-position {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: bold;
            font-size: 26px;
        }

        .ranking-card-icon {
            padding: 0 1em;
            div {
                width: 45px;
                height: 45px;
            }
        }

        .ranking-card-display-name {
            margin: 0;
            font-size: 16px;
        }

        .ranking-card-pulse-icon {
            padding-left: 2em;
        }

        .ranking-card-pulses {
            font-weight: 600;
            font-size: 26px;
            line-height: 39px;
            text-align: right;
        }

        h1 {
            margin: 0;
        }
    }
`
