import { ReactNode, useRef } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { Icon } from './Icon'

export const CodeBlock = ({
    children,
    clipboard = true,
}: {
    children: ReactNode
    clipboard?: boolean
}) => {
    const ref = useRef<HTMLElement>(null)

    const copyToClipboard = () => {
        if (!ref.current || !navigator.clipboard || !children) return
        const text = ref.current?.innerText
        navigator.clipboard.writeText(text)
    }
    return (
        <Pre>
            {clipboard && (
                <button onClick={copyToClipboard}>
                    <Icon icon={['fal', 'clipboard']} size="xl" />
                </button>
            )}
            <code ref={ref}>{children}</code>
        </Pre>
    )
}

const Pre = styled.pre`
    color: white;
    background-color: ${defaultTheme.color.primary};
    padding: 2em;
    border-radius: 6px;
    position: relative;

    button {
        position: absolute;
        cursor: pointer;
        opacity: 0.5;
        top: 0.5em;
        right: 0.5em;
        border: 0;
        outline: 0;
        background: transparent;
        color: white;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
`
