export const validationDigit = (ci: string): number => {
    let a = 0
    let i = 0
    if (ci.length <= 6) {
        for (i = ci.length; i < 7; i++) {
            ci = '0' + ci
        }
    }
    for (i = 0; i < 7; i++) {
        a += (parseInt('2987634'[i]) * parseInt(ci[i])) % 10
    }
    return a % 10 === 0 ? 0 : 10 - (a % 10)
}

export const validateCI = (ci: string): boolean => {
    ci = cleanCI(ci)
    const dig = ci[ci.length - 1]
    ci = ci.replace(/[0-9]$/, '')
    return dig === validationDigit(ci).toString()
}

export const randomCI = (): string => {
    let ci = Math.floor(Math.random() * 10000000).toString()
    ci = ci.substring(0, 7) + validationDigit(ci)
    return ci
}

export const cleanCI = (ci: string): string => {
    return ci.replace(/\D/g, '')
}
