import { useQuery } from '@tanstack/react-query'
import { getOneCourse } from '../api-marketplace'

export const useCourse = (slug?: string) => {
    const queryKey = ['Course', slug, 'Info']
    const queryFn = () => getOneCourse(`${slug}`)

    const { data: course, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: !!slug,
    })

    return { course, ...rest }
}
