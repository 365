import { useQuery } from '@tanstack/react-query'
import { getUserCourses as queryFn } from '../../infrastructure/api-activation'
import { useToken } from '../useToken'
import { extendCourseData } from '../../helpers/courses'

export const useUserCourses = () => {
    const { _id: userId, _classId } = useToken()

    const queryKey = ['User', 'Courses', userId ?? 'Unknown']

    const { data: rawCourses, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: !!userId,
    })

    const courses = rawCourses?.map(extendCourseData)

    const getCourseData = (courseId?: string) =>
        courses?.find(course => course.courseId === courseId)

    const currentCourse = getCourseData(_classId)

    const currentCourseName = currentCourse?.networkName ?? currentCourse?.courseName

    return {
        courses,
        currentCourse,
        currentCourseName,
        getCourseData,
        ...rest,
    }
}
