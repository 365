import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import FaqPage from './static/Faq'
import MethodologyPage from './static/Methodology'
import InfoPage from './static/Info'
import InfoDiscordPage from './static/InfoDiscord'
import ContentPage from './static/Content'

export const DynamicContent = () => {
    const { slug } = useParams()

    const render = (): ReactNode => {
        switch (slug?.toLowerCase()) {
            case 'faq':
                return <FaqPage />
            case 'methodology':
                return <MethodologyPage />
            case 'info':
                return <InfoPage />
            case 'info-discord':
                return <InfoDiscordPage />
            case 'content':
                return <ContentPage />
            default:
                break
        }
    }

    return <>{render()}</>
}
