import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { UIContext } from '../../context/UIContext'
import { GACategory, SendGAEvent } from '../../helpers/GAEvents'
import { useDownloadFile } from '../../hooks/useDownloadFile'
import { Certificate } from '../../interfaces/api'
import LoadingPage from '../../pages/static/LoadingPage'
import { PrimaryBtnOutline } from '../Buttons/PrimaryBtn'

interface IProps {
    payload: Certificate
}

export const CertificateModal = ({ payload }: IProps) => {
    const [ready, setReady] = useState(false)
    const [certificate, setCertificate] = useState<Certificate>()
    const { dispatchModal } = useContext(UIContext)
    const { download } = useDownloadFile()

    const certificateImage = async () => {
        const { data } = await axios.get<{ certificates: Certificate[] }>(
            `${process.env.REACT_APP_WIDGET_API}/v1/profile/certificates?generate=${payload.type}`
        )
        setCertificate(data.certificates[0])
        setReady(true)
    }

    async function downloadFile() {
        const { data } = await axios.get<Blob>(
            `${process.env.REACT_APP_WIDGET_API}/v1/profile/downloadCertificate?certificateType=${payload.type}`,
            {
                responseType: 'blob',
            }
        )
        download(data, `${certificate?.templateName}.jpeg`, 'image/jpeg')
        SendGAEvent(GACategory.CERTIFICATION, `DownloadCertificate`)
    }

    async function getUrlLinkedin() {
        const { data } = await axios.get<{ urlCertificate: string }>(
            `${process.env.REACT_APP_WIDGET_API}/v1/profile/getCertificate?certificateType=${payload.type}`
        )
        window.open(data.urlCertificate, '_blank')
        SendGAEvent(GACategory.CERTIFICATION, `UploadToLinkedin`)
    }

    useEffect(() => {
        certificateImage()
        SendGAEvent(GACategory.CERTIFICATION, `CertificationClicked`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!ready) {
        return (
            <CertificateModalStyled>
                <LoadingPage />
            </CertificateModalStyled>
        )
    }

    return (
        <CertificateModalStyled>
            <img className="img-certificate" src={certificate?.url} alt="certificate img" />
            <div className="actions-container">
                <PrimaryBtnOutline colorTheme="dark" onClick={downloadFile}>
                    <FontAwesomeIcon className="icon-item" icon={['fas', 'download']} />
                    Descargar Certificado
                </PrimaryBtnOutline>
                <PrimaryBtnOutline onClick={getUrlLinkedin} colorTheme="dark">
                    <FontAwesomeIcon className="icon-item" icon={['fab', 'linkedin']} />
                    Publicar en Linkedin
                </PrimaryBtnOutline>
                <button className="close-btn" onClick={() => dispatchModal({ type: null })}>
                    <FontAwesomeIcon icon={['fas', 'times']} />
                </button>
            </div>
        </CertificateModalStyled>
    )
}

const CertificateModalStyled = styled.div`
    overflow: hidden;
    width: 740px;
    height: 620px;
    background-color: white;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 20px;
    border: 1px solid ${props => props.theme.color.gray};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (max-width: 740px) {
        width: 100%;
        height: auto;
    }

    @media screen and (max-height: 620px) {
        .actions-container {
            margin-top: 10px !important;
        }
    }

    .close-btn {
        background: none;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        position: absolute;
        top: 18px;
        right: 18px;
        padding: 10px 15px;
        z-index: 100000;
        font-size: 16px;
        cursor: pointer;
        &:hover {
            background-color: #fafafa;
        }
    }

    .img-certificate {
        margin-top: 82px;
        width: 100%;
        height: auto;
        box-shadow: 0px 0px 20px #00000029;
    }

    .actions-container {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 52px;
    }

    .icon-item {
        margin-right: 9px;
        font-size: 16px;
    }
`
