import { getStudyPlan } from '../../../infrastructure/api-widget'
import { useContentDay } from './useContentDay'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useUserCourses } from '../../../hooks/queries/useUserCourses'

export const useStudyPlan = (courseId?: string) => {
    const navigate = useNavigate()
    const { getCourseData } = useUserCourses()
    const { contentDay } = useContentDay(courseId)

    const currentCourse = getCourseData(courseId)
    const queryKey = ['Study Plan', courseId]

    const asyncCourse = currentCourse?.asyncCourse
    const courseStatus = currentCourse?.status

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: () => getStudyPlan(`${courseId}`),
        enabled: !!courseId && !currentCourse?.requirePayment && courseId !== 'undefined',
        retry: false,
    })

    const getPrevAndNextStepId = (
        currentModuleId: string,
        currentSectionId: string,
        stepId: string,
        studyPlan: StudyPlanResponse
    ): { prev: string | null; next: string | null } => {
        const abbleModules = studyPlan?.plan.modules.filter(module => module.abble)
        const abbleSections = abbleModules?.flatMap(module =>
            module.sections
                .filter(section => section.abble)
                .map(section => ({
                    ...section,
                    moduleId: module._id,
                }))
        )
        const allSteps = abbleSections?.flatMap(section =>
            section.steps.map(step => ({
                ...step,
                sectionId: section._id,
                moduleId: section.moduleId,
            }))
        )

        let prev = null
        let next = null
        if (!allSteps) return { prev, next }

        const currentStepIndex = allSteps.findIndex(({ moduleId, sectionId, _id: _stepId }) => {
            return (
                moduleId === currentModuleId && sectionId === currentSectionId && _stepId === stepId
            )
        })

        if (currentStepIndex === -1) return { prev, next }
        if (currentStepIndex > 0) prev = allSteps[currentStepIndex - 1]._id
        if (currentStepIndex < allSteps.length - 1) next = allSteps[currentStepIndex + 1]._id

        return { prev, next }
    }

    const getModuleAndSection = (stepId?: string) => {
        let moduleId: string | undefined
        let sectionId: string | undefined

        if (!studyPlan || !stepId) return { moduleId, sectionId }

        const found = studyPlan.plan.modules
            .flatMap(module => module.sections)
            .flatMap(section => section.steps)
            .find(step => step._id === stepId)

        if (found) {
            const module = studyPlan.plan.modules.find(module =>
                module.sections.some(section => section.steps.includes(found))
            )
            const section = module?.sections.find(section => section.steps.includes(found))

            if (module && section) {
                moduleId = module._id
                sectionId = section._id
            }
        }

        return { moduleId, sectionId }
    }

    const ParsedStudyPlan = () => {
        if (!data || !contentDay) return null
        const { plan } = data

        const {
            contentDist: { module, section },
        } = contentDay

        const todayModule = plan.modules.find(({ _id }) => _id === module)
        const todaySection = todayModule?.sections.find(({ _id }) => _id === section)

        const openModuleIndex = plan.modules.findIndex(({ _id }) => _id === todayModule?._id)
        const openSectionIndex = plan?.modules[openModuleIndex]?.sections?.findIndex(
            ({ _id }) => _id === todaySection?._id
        )

        const endModuleIndex = plan.modules.findIndex(({ _id }) => _id === module)
        const endSectionIndex = plan?.modules[endModuleIndex]?.sections?.findIndex(
            ({ _id }) => _id === section
        )

        const parsedModules = plan?.modules?.map((module, planModuleIndex) => {
            // If course is async, all modules are abble
            if (asyncCourse)
                return {
                    ...module,
                    abble: true,
                    done: false,
                    sections: module.sections.map(section => ({
                        ...section,
                        abble: true,
                        done: false,
                        steps: section.steps.map(step => ({
                            ...step,
                            abble: true,
                            done: false,
                        })),
                    })),
                }

            // If course is not async, we need to decide if module is abble or not
            const decideSections = module?.sections?.map((section, planSectionIndex) => {
                if (planSectionIndex < openSectionIndex || courseStatus === 'finished')
                    return { ...section, abble: true, done: true }

                if (
                    planSectionIndex >= openSectionIndex &&
                    (planModuleIndex < endModuleIndex || planSectionIndex <= endSectionIndex)
                )
                    return { ...section, abble: true, done: false }

                if (planSectionIndex > endSectionIndex)
                    return { ...section, abble: false, done: false }

                return { ...section, abble: false, done: false }
            })
            const doneSection = module?.sections?.map(section => ({
                ...section,
                abble: true,
                done: true,
            }))
            const undoneSection = module?.sections?.map(section => ({
                ...section,
                abble: false,
                done: false,
            }))

            // Building module
            if (planModuleIndex < openModuleIndex)
                return { ...module, sections: doneSection, abble: true, done: true }

            if (planModuleIndex <= endModuleIndex && planModuleIndex >= openModuleIndex)
                return { ...module, sections: decideSections, abble: true, done: false }

            if (planModuleIndex > endModuleIndex)
                return { ...module, sections: undoneSection, abble: false, done: false }

            return { ...module, sections: undoneSection, abble: false, done: false }
        })

        return { ...data, plan: { ...data.plan, modules: parsedModules } }
    }

    // If course is async, show first module, section and step as today content
    const moduleOfDay = asyncCourse
        ? data?.plan.modules[0]
        : data?.plan.modules.find(({ _id }) => _id === contentDay?.contentDist.module)
    const sectionOfDay = asyncCourse
        ? moduleOfDay?.sections[0]
        : moduleOfDay?.sections.find(({ _id }) => _id === contentDay?.contentDist.section)
    const stepOfDay = sectionOfDay?.steps[0]
    const contentDescription = sectionOfDay?.title

    const todayContent = {
        module: moduleOfDay,
        section: sectionOfDay,
        step: stepOfDay,
    }

    const goToStepOfDay = () => {
        stepOfDay?._id && navigate(`/content/step/${stepOfDay._id}`)
    }

    const contentDayDescription = contentDescription ?? ''

    const ContentDayLink = (
        props: React.DetailedHTMLProps<
            React.ButtonHTMLAttributes<HTMLButtonElement>,
            HTMLButtonElement
        >
    ) =>
        !contentDescription ? null : (
            <button onClick={goToStepOfDay} children={contentDayDescription} {...props} />
        )

    const studyPlan = data ? ParsedStudyPlan() : undefined

    let totalSteps = 0
    let alreadyStep = 0
    let progressPercent = 0

    if (courseStatus === 'finished') progressPercent = 100
    if (courseStatus === 'studying') {
        studyPlan?.plan.modules.forEach(module => {
            module.sections.forEach(section => {
                totalSteps += section.steps.length
                if (section.abble) alreadyStep += section.steps.length
            })
        })
        if (totalSteps) progressPercent = Math.round((alreadyStep / totalSteps) * 100)
    }

    const CourseProgress = (
        props: React.DetailedHTMLProps<
            React.ProgressHTMLAttributes<HTMLProgressElement>,
            HTMLProgressElement
        >
    ) => <progress id="course-progress" max="100" value={progressPercent} {...props} />

    return {
        studyPlan,
        todayContent,
        contentDayDescription,
        getPrevAndNextStepId,
        getModuleAndSection,
        goToStepOfDay,
        ContentDayLink,
        CourseProgress,
        progressPercent,
        ...rest,
    }
}

export interface TodayContent {
    module: Module
    section: Section
    step: Step
}

export interface Course {
    name: string
}

export interface Step {
    _id: string
    title: string
    type: string
}

export interface Section {
    _id: string
    title: string
    type: string
    steps: Step[]
    abble: boolean
    done: boolean
}

export interface Module {
    _id: string
    title: string
    type: string
    sections: Section[]
    abble: boolean
    done: boolean
}

export interface Plan {
    _id: string
    title: string
    type: string
    createdAt: string
    updatedAt: string
    modules: Module[]
}

export interface StudyPlanResponse {
    course: Course
    plan: Plan
}
