import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import styled from 'styled-components'
import { UIContext } from '../../context/UIContext'
import { Publicity } from '../../interfaces/api'
import { GACategory, SendGAEvent } from '../../helpers/GAEvents'
import { UserContext } from '../../context/UserContext'
interface IProps {
    payload: Publicity
}

export const AdsModal = ({ payload }: IProps) => {
    const { dispatchModal } = useContext(UIContext)
    const { setLxpInfo, lxpInfo } = useContext(UserContext)

    const handleClose = () => {
        setLxpInfo({ ...lxpInfo, viewAdvertising: true })
        dispatchModal({ type: null })
    } 

    return (
        <AdsModalStyled>
            <a onClick={() => SendGAEvent(GACategory.ADS, 'AdClicked')} href={payload.urlPage} className="img-publicity" rel="noreferrer" target="_blank">
                <img className="img-publicity" src={payload.urlImage} alt={payload.title} />
            </a>
            <button className="close-btn" onClick={ handleClose }>
                <FontAwesomeIcon icon={['fas', 'times']} />
            </button>
        </AdsModalStyled>
    )
}

const AdsModalStyled = styled.div`
    overflow: hidden;
    height: 620px;
    background-color: white;
    border-radius: 20px;
    @media screen and (max-width: 700px) {
        height: 50% !important;
    }

    .close-btn {
        background: white;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        position: absolute;
        top: 18px;
        right: 18px;
        padding: 10px 15px;
        z-index: 100000;
        font-size: 16px;
        cursor: pointer;
        &:hover {
            background-color: #fafafa;
        }
    }

    .img-publicity {
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }

    .icon-item {
        margin-right: 9px;
        font-size: 16px;
    }
`
