import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { CardGeneral } from '../../../components/CardGeneral'
import { Icon } from '../../../components/Icon'
import { useDetails } from '../hooks/useDetails'
import { ModalType, useUIContext } from '../../../context/UIContext'
import { defaultTheme } from '../../../styles/themes/defaultTheme'

export const CourseDetails = () => {
    const navigate = useNavigate()
    const { slug } = useParams()
    const { t } = useTranslation('courseDetails')
    const { dispatchModal } = useUIContext()
    const { details } = useDetails(slug)

    if (!details) return null

    const { studyPlan, longDescription, title, tags, eggCoins, lang } = details
    const steps = studyPlan.length ?? 0

    const __html = longDescription ?? undefined

    return (
        <CourseDetailsStyle>
            <article className="card">
                <header>
                    <Tags>
                        <img src={`/images/flag.${lang}.svg`} alt={t(lang)} />
                        {tags.map(({ color, name }) => (
                            <Label key={name} color={color}>
                                {name}
                            </Label>
                        ))}
                    </Tags>
                </header>
                <main>
                    <div className="title">{title}</div>
                    <div className="subtitle"></div>
                </main>
                {(!!steps || !!eggCoins) && (
                    <>
                        <span className="divider"></span>
                        <footer className="display-flex">
                            {!!steps && (
                                <span>
                                    <button
                                        className="icon white outlined"
                                        onClick={() =>
                                            dispatchModal({ type: ModalType.USER_BALANCE_GLOSSARY })
                                        }
                                    >
                                        <Icon
                                            icon={['fal', 'shoe-prints']}
                                            rotation={270}
                                            size="xl"
                                        />
                                        <span>{steps}</span>
                                    </button>
                                </span>
                            )}
                            {!!eggCoins && (
                                <span>
                                    <button
                                        className="icon white"
                                        onClick={() =>
                                            dispatchModal({ type: ModalType.USER_BALANCE_GLOSSARY })
                                        }
                                    >
                                        <Icon
                                            icon={['fal', 'coin-blank']}
                                            rotation={270}
                                            size="xl"
                                        />
                                        <span>{eggCoins}</span>
                                    </button>
                                </span>
                            )}
                        </footer>
                    </>
                )}
            </article>
            <div className="only-mobile">
                <br />
                <br />
                <button
                    className="primary w-100"
                    children={t('Sign up now')}
                    onClick={() => navigate(`/marketplace/${slug}/enroll`)}
                />
            </div>
            <Description dangerouslySetInnerHTML={{ __html }} className="t-large" />
            {!!studyPlan.length && (
                <TopicList>
                    <h3>{t('Topic List')}</h3>
                    {studyPlan.map(({ title }, key) => (
                        <div className="topic-card" key={title}>
                            <span>
                                {t('Step')} {key + 1}:{' '}
                                <span style={{ paddingRight: '0.5em' }}></span> {title}
                            </span>
                        </div>
                    ))}
                </TopicList>
            )}
            <br />
        </CourseDetailsStyle>
    )
}

export const CourseDetailsStyle = styled.section`
    padding: 0.5rem 2rem;
    & > article.card {
        min-height: 300px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: max-content auto min-content max-content;
        align-items: center;
        & > main {
            align-self: flex-start;
            margin-top: 4rem;
        }
    }
`

export const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    align-items: center;
    img {
        height: 1.2em;
        border-radius: 4px;
    }
`

export const Label = styled.label<{ color: string }>`
    background-color: ${({ color }) => color};
    display: inline-block;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 0.85em;
`

const Description = styled.div`
    padding: 2em 0.8em;
`

const TopicList = styled.div`
    .topic-card {
        background-color: #0037500f;
        padding: 1em 2.3em;
        margin: 2em 0;
        border-radius: ${defaultTheme.borderRadius};
        span {
            display: inline-block;
            font-size: 1.15em;
            font-weight: bold;
            line-height: 2em;
        }
    }
`
