import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NetworkDTO } from '../api-marketplace'
import { useCohorts } from '../hooks/useCohorts'
import { useFrequency } from '../hooks/useFrequency'
import { ButtonsBlock } from './ButtonsBlock'
import { EnrollProps } from './Enroll'

export const AsyncView = (props: EnrollProps & { course: NetworkDTO }) => {
    const { t } = useTranslation('enroll')
    const { course } = props
    const { frequencies } = useFrequency(`${course?.id}`)
    const courseId = frequencies?.filter(frequency =>
        frequency.courses.some(course => course.id === course?.id)
    )[0]?.courses[0]?.id
    const { cohorts } = useCohorts({ slug: course?.slug ?? course?.id, courseId })
    const cohortId = cohorts?.[0]?.id

    useEffect(() => {
        props.choices[1]({ cohortId })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cohortId])

    return (
        <AsyncStyle>
            <main>
                <h1 className="title">{t(`Schedule your learning!`)}</h1>
                <br />
                <p className="light center">
                    {t(
                        'You now have access to the course content and the freedom to choose the time and pace of study that is most convenient for you.'
                    )}
                </p>
                <p className="light center">
                    {t('We recommend you dedicate at least 2 hours per week to achieve your goal.')}
                </p>
            </main>
            <footer>
                <ButtonsBlock {...{ ...props, step: 'async' }} />
            </footer>
        </AsyncStyle>
    )
}

const AsyncStyle = styled.section`
    height: 100%;
    display: grid;
    align-items: center;
    align-content: space-evenly;
    main {
        display: grid;
        justify-items: center;
    }
    .title {
        text-transform: lowercase;
        &:first-letter {
            text-transform: uppercase;
        }
    }
`
