import { getMeetingTokens } from '../infrastructure/api-hca'
import { useState } from 'react'
import { useTimeline } from './useTimeline'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../context/UIContext'
import { useUserCourses } from './queries/useUserCourses'

export const useOpenMeet = (courseId?: string) => {
    const { t } = useTranslation('dashboard')
    const { showToast, showError } = useUIContext()
    const { currentEvent } = useTimeline(courseId)
    const { getCourseData } = useUserCourses()
    const course = getCourseData(courseId)
    const [isOpening, setIsOpening] = useState(false)

    if (!course || !course.meetings) return { openMeet: async () => null }

    const { algorithmId, meetings } = course

    const openMeet = async (): Promise<Window | null> => {
        if (!courseId) return null

        setIsOpening(true)

        let meetUrl: string | null = null
        try {
            let { room } = currentEvent ?? {}

            // Handle the case when currentEvent is undefined. Eg. when the class starts
            if (!currentEvent) {
                if (meetings.general) room = 'general'
                else if (meetings.team) room = 'teams'
                else {
                    showToast(t('Wait a moment while we generate the teams'))
                    return null
                }
            }

            const provider = room === 'teams' ? meetings.team?.provider : meetings.general?.provider

            switch (provider) {
                case 'egg':
                case 'zoom-hybrid':
                    const { general, teams } = await getMeetingTokens(algorithmId)

                    const data = room === null ? null : room === 'teams' ? teams : general ?? teams
                    if (!data) return null

                    meetUrl = data.url ?? null
                    break

                default:
                    meetUrl = meetings.general?.url ?? null
                    break
            }

            if (meetUrl) return window.open(meetUrl, '_blank')

            return null
        } catch (error) {
            showError(t('An error occurred while trying to open the meeting'))
            console.error(error)
            return null
        } finally {
            setIsOpening(false)
        }
    }

    return { openMeet, isOpening }
}
