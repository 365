
export const useDownloadFile = () => {

    const download = (data: Blob, nameFile: string, contentType: string) => {
        const blob = new Blob([data], {type: contentType });
        let fileUrl = window.URL.createObjectURL(blob)
        let fileLink = document.createElement("a")
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", nameFile )
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
    }

    return{
        download
    }
}

