import Axios from 'axios'
import { refreshInterceptor } from './apis'

const { REACT_APP_EGG_API } = process.env

export const ApiEgg = Axios.create({
    baseURL: REACT_APP_EGG_API,
})

ApiEgg.interceptors.response.use(
    ok => ok,
    error => refreshInterceptor(error, ApiEgg)
)

export const getTimeline = (courseId?: string) =>
    ApiEgg.get<GetTimelineResponse>(`/class/${courseId}/timeline`).then(({ data }) => data.data)

export interface GetTimelineResponse {
    data: {
        timeline: TimelineEvent[]
        currentEvent?: TimelineEvent
        nextEvent?: TimelineEvent
        _courseId: string
    }
}

export type MeetingRoom = 'general' | 'teams' | null

export interface TimelineEvent {
    room: MeetingRoom
    date: string
    status: 'past' | 'current' | 'future'
    _algorithmId?: string
}

export interface TokenPayload {
    _id: string
    _profileId: string
    _classId: string
    _hqId: string
    role: string
    _teamId: string
    iat: number
    exp: number
}
