import styled from 'styled-components'
import Bolt from '../../assets/bolts/rayo_color.svg'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { MyTeamDataDTO, givePulse } from '../../infrastructure/api-widget'
import { useUIContext } from '../../context/UIContext'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { useToken } from '../../hooks/useToken'
import { useMyTeam } from '../../hooks/useMyTeam'

export const PulseButton = ({ _profileToId }: { _profileToId: string }) => {
    const { myTeam } = useMyTeam()
    const { _profileId } = useToken()
    const queryClient = useQueryClient()
    const { showError } = useUIContext()
    const { currentCourse } = useUserCourses()
    const _algorithmId = currentCourse?.algorithmId ?? undefined

    const active = myTeam?.me.voteData._profileToId === _profileToId
    const queryKey = ['User', 'My Team', _profileId]

    const { mutate, status } = useMutation({
        mutationKey: ['pulse'],
        mutationFn: () => givePulse({ _profileToId, _algorithmId }),
        onMutate: () => {
            const snapshot = queryClient.getQueryData<MyTeamDataDTO>(queryKey)
            if (!snapshot) return
            queryClient.setQueryData<MyTeamDataDTO>(queryKey, {
                ...snapshot,
                me: {
                    ...snapshot.me,
                    voteData: {
                        _profileFromId: `${_profileId}`,
                        _profileToId,
                        createdAt: new Date().toISOString(),
                    },
                },
            })
        },
        onError: error => {
            queryClient.invalidateQueries({ queryKey })
            showError(error)
        },
    })

    // If the user is trying to pulse himself, we don't show the button
    if (_profileToId === _profileId) return null
    return (
        <Button
            onClick={() => mutate()}
            disabled={!_algorithmId || active || status === 'pending'}
            className={active ? 'active' : 'inactive'}
        >
            <img src={Bolt} alt="Pulse" />
        </Button>
    )
}

const Button = styled.button`
    display: grid;
    place-content: center;
    width: 32px;
    height: 32px;
    padding: 11px;
    border: 0px;
    border-radius: 0.625rem;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 55, 80, 0.08) 0px 0px 20px 0px;
    cursor: pointer;

    &.active {
        animation: pulse 0.5s;
    }

    &.active,
    &:hover {
        background: ${defaultTheme.color.primary};
    }

    & img {
        height: 18px;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
`
