import { UseQueryOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { useToken } from '../useToken'
import { API_AXIOS_ERROR } from '../../interfaces/api-activation'
import { updateUser as mutationFn } from '../../pages/register/api-register'
import { getUserInfo } from '../../infrastructure/api-activation'

export const useUpdateUser = (options?: Options) => {
    const queryClient = useQueryClient()

    const { _id } = useToken()
    const userQueryKey = ['User', _id]

    const {
        mutate: updateUser,
        mutateAsync: updateUserAsync,
        isPending: isUpdating,
        error: api_error,
        status: updateStatus,
    } = useMutation({
        mutationFn,
        onMutate: async values => {
            await queryClient.cancelQueries({ queryKey: ['User'], exact: false })
            const snapshot = queryClient.getQueryData<TData>(userQueryKey)
            queryClient.setQueryData<TData>(userQueryKey, prev =>
                prev ? { ...prev, user: { ...prev.user, ...values } } : prev
            )
            return snapshot
        },
        onError: (err: API_AXIOS_ERROR, _, snapshot) =>
            queryClient.setQueryData(userQueryKey, snapshot),
        onSuccess: values =>
            queryClient.setQueryData<TData>(userQueryKey, prev =>
                prev ? { ...prev, user: { ...prev.user, ...values } } : prev
            ),
    })

    return {
        update: updateUser,
        updateAsync: updateUserAsync,
        isUpdating,
        api_error,
        updateStatus,
    }
}

type TData = Awaited<NonNullable<ReturnType<typeof getUserInfo>>>
type Options = UseQueryOptions<TData>
