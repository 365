import styled from 'styled-components'
import { GoToCourseButton } from './GoToCourseButton'
import { MeetButton } from './MeetButton'
import { UserCourseDTOExtended } from '../../interfaces/api-activation'
import { useTranslation } from 'react-i18next'

export const AssignationBar = (props: Props) => (
    <>
        <Left {...props} />
        <Right {...props} />
    </>
)

const Left = ({ status, asyncCourse }: Props) => {
    const { t } = useTranslation('dashboard')

    if (asyncCourse) return <div />

    return <div>{status === 'finished' ? t('Your course has ended.') : null}</div>
}

const Right = (props: Props) => (
    <RightSection className="right">
        <GoToCourseButton {...props} />
        <MeetButton {...props} />
    </RightSection>
)

const RightSection = styled.section`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    gap: 1em;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    button:only-child {
        min-width: 48% !important;
        margin-right: 0;
        margin-left: auto !important;
    }

    & > button {
        padding: 0.438rem 1.125rem !important;
        font-size: 0.929rem !important;
        box-shadow: none !important;
        min-width: 170px !important;
    }

    & > button.red {
        margin-left: unset !important;
    }
`

type Props = UserCourseDTOExtended & { isInside: boolean; hasBranding?: boolean }
