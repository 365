export const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong. Please try again later.'

export const getMessageFromError = (error: any): string => {
    if (typeof error === 'string') return error

    if (error?.response && 'data' in error.response && 'message' in error.response.data)
        return error.response.data.message

    if (!Array.isArray(error) && typeof error === 'object' && 'message' in (error ?? {}))
        return error.message

    return DEFAULT_ERROR_MESSAGE
}
