import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import networkImage from '../../../assets/network_transparent_bg.png'
import { Icon } from '../../../components/DesignSystem/Molecules/NavBar/NavBar.styles'
import { useUserCourses } from '../../../hooks/queries/useUserCourses'
import { useIntercom } from '../../../hooks/useIntercom'
import { setNewAccessToken } from '../../../infrastructure/apis'
import { UserCoursesDTO } from '../../../interfaces/api-activation'
import { defaultTheme } from '../../../styles/themes/defaultTheme'
import LoadingPage from '../../static/LoadingPage'

const { REACT_APP_WHATSAPP_BUSINESS } = process.env

export const CongratsView = () => {
    const { t } = useTranslation('enroll')
    const { slug } = useParams()
    const { courses, isLoading } = useUserCourses()

    const course = courses?.find(course => course.slug === slug)
    const freeCourse = course && !course?.requirePayment

    if (isLoading || course === undefined || freeCourse === undefined)
        return <LoadingPage metric="%" />
    return (
        <CongratsStyle>
            <span>
                <header>
                    <img src={networkImage} alt={t('Cooperation')} />
                </header>
                <main>
                    {freeCourse ? (
                        <FreeCourseComponent course={course} />
                    ) : (
                        <PaymentCourseComponent />
                    )}
                </main>
            </span>
            <footer>
                <Icon icon={['fal', 'info-circle']} active={0} />
                <p>{t('You have doubts?')}</p>
                <button
                    className="link"
                    children={t('Contact our digital talent advisors')}
                    onClick={() => window.open(REACT_APP_WHATSAPP_BUSINESS, '_blank')}
                />
            </footer>
        </CongratsStyle>
    )
}

const FreeCourseComponent = ({ course }: { course?: UserCoursesDTO }) => {
    const { t } = useTranslation('enroll')
    const navigate = useNavigate()

    return (
        <>
            <div className="subtitle">{t(`Congrats!`)}</div>
            <p>{t('You can now start studying.')}</p>
            <button
                className="primary w-100"
                children={t('Start')}
                onClick={() => {
                    course && setNewAccessToken(course['access-token'])
                    navigate(`/dashboard/${course?.courseId}`)
                }}
            />
        </>
    )
}

const PaymentCourseComponent = () => {
    const { t } = useTranslation('enroll')
    const { chatWithSupport } = useIntercom()

    return (
        <>
            <div className="subtitle">{t(`We're almost there!`)}</div>
            <p>
                {t(
                    'To secure your spot, click the button below and then select the "Pay for the course" option.'
                )}
            </p>

            <button
                className="primary w-100"
                children={t('Secure my spot')}
                onClick={() => chatWithSupport('')}
            />
        </>
    )
}

export const CongratsStyle = styled.section`
    height: 100%;
    margin: 0 auto;
    display: grid;
    align-items: center;
    text-align: center;
    p {
        margin: 0;
    }
    header {
        img {
            width: 80%;
            margin-bottom: 2.8em;
        }
    }
    main {
        border-bottom: 1px solid ${defaultTheme.color.lightGray};
        padding-bottom: 3em;
        max-width: 400px;
        margin: 0 auto;
        .subtitle {
            line-height: 28px;
            padding-bottom: 0.3em;
        }
        button {
            margin: 2.8em 0;
        }
    }
    footer {
        svg {
            font-size: 1.86em;
            margin: 1.4em 0 0.4em 0;
        }
        p {
            font-weight: 700;
        }
        button {
            font-weight: 500 !important;
        }
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        height: calc(100vh);
        max-height: calc(100vh);
        border-radius: 0;
    }
`
