import { useNavigate } from 'react-router-dom'

export const useAppNavigate = () => {
    const nav = useNavigate()

    const urlParams = new URLSearchParams(window.location.search)
    const searchParams = Object.fromEntries(urlParams.entries())
    const searchParamsSize = Object.keys(searchParams).length

    const navigate = (route: AppRoute, options?: { keepSearchParams?: boolean }) => {
        const navigationalUrl =
            options?.keepSearchParams !== false && !!urlParams.size
                ? `${route}?${urlParams}`
                : route

        return nav(navigationalUrl)
    }

    return {
        navigate,
        searchParams,
        searchParamsSize,
    }
}

type AppRoute = `/dashboard/${string}?sectionId=${string}` | `/content/step/${string}`
