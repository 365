import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps, PropsWithChildren, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { CardGeneralSkeleton } from '../../../components/CardGeneral'
import { AssignationBar } from '../../../components/CourseCard/AssignationBar'
import { useUser } from '../../../hooks/queries/useUser'
import { useUserCourses } from '../../../hooks/queries/useUserCourses'
import { useIntercom } from '../../../hooks/useIntercom'
import { useOrganization } from '../../../hooks/useOrganization'
import { UserCourseDTOExtended } from '../../../interfaces/api-activation'

export const MyCourses = () => {
    const { isLoading } = useOrganization()
    const { courses, isFetching } = useUserCourses()

    return (
        <Section>
            <Suspense fallback={<Skeleton />}>
                {isFetching || isLoading ? (
                    <Skeleton />
                ) : (
                    <>
                        {courses?.length ? (
                            <Grid coursesLength={courses.length}>
                                {courses?.map(course => (
                                    <Card {...course} key={course.courseId} />
                                ))}
                            </Grid>
                        ) : (
                            <NoCourses />
                        )}
                    </>
                )}
            </Suspense>
        </Section>
    )
}

const Card = (props: UserCourseDTOExtended) => {
    const { networkName, courseName } = props

    return (
        <article className="card custom">
            <main className="card-content">
                <div className="title">{networkName ?? courseName}</div>
                <div className="subtitle"></div>
            </main>
            <footer className="card-footer">
                <AssignationBar isInside={false} {...props} />
            </footer>
        </article>
    )
}

const Skeleton = () => (
    <>
        <div className="title skeleton" style={{ height: '52px', width: '70%' }}></div>
        <CardGeneralSkeleton className="card-general" style={{ height: 120 }} />
    </>
)

const NoCourses = () => {
    const { t } = useTranslation('home')
    const { user } = useUser()
    const navigate = useNavigate()

    if (user?.hideMarketplace) return <HiddenMarketplace />

    return (
        <p>
            {t('You have not enrolled in any course yet, choose one from the section')}{' '}
            <button onClick={() => navigate('/ecosystem')} className="link">
                <b>{t('Ecosystem')}</b>
            </button>
        </p>
    )
}

const HiddenMarketplace = () => {
    const { t } = useTranslation('home')
    const { openChat } = useIntercom()

    return (
        <Centered>
            <div className="column-center">
                <FontAwesomeIcon icon={['fal', 'screen-users']} size="4x" />
                <h2>{t('You are not enrolled in any course')}</h2>
                <p
                    style={{
                        opacity: '0.8',
                        fontSize: 15,
                    }}
                >
                    {t('Contact to your organization or to our')} <br />
                    <button className="bold" onClick={openChat}>
                        {t('technical support')}
                    </button>{' '}
                    {t('to receive help.')}
                </p>
            </div>
        </Centered>
    )
}

const Section = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: max-content auto;
    grid-gap: 3rem;
    & > .title {
        margin-top: 2rem;
    }
    @media screen and (max-width: 768px) {
        height: unset;
    }
`

const Grid = styled.div<{ coursesLength: number }>`
    display: grid;
    grid-template-columns: ${({ coursesLength }) =>
        coursesLength === 1 ? '426px' : 'repeat(auto-fit, minmax(426px, 1fr))'};
    gap: 15px;
    margin-top: 2rem;
    @media (max-width: 768px) {
        flex-direction: column;
        grid-template-columns: auto;
        padding-bottom: 3rem;
    }
    & article.card {
        min-height: 230px;
        display: grid;
        grid-template-rows: [main] 1fr [footer] max-content;
        box-sizing: border-box;
        border: 1px solid var(--light-mode-grey-light-1, #e8ecee);
        @media screen and (max-width: 768px) {
            width: 100%;
            min-height: auto;
        }
        & main.card-content {
            padding-top: 1.429rem;
            & > .title {
                font-size: 1.714rem;
                font-weight: 500;
                letter-spacing: -0.48px;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 1rem;
            }
        }
        & footer.card-footer {
            display: grid;
            grid-template-columns: auto 1fr;
            place-content: center;
            border-top: 1px solid rgba(255, 255, 255, 0.098);
            padding-top: 2em;
            justify-content: space-between;
            justify-items: center;
            min-height: 44px;

            & > div {
                display: grid;
                place-items: center;
            }

            & > div:first-child {
                justify-content: start;
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr;
                text-align: center;
                gap: 0;
                padding-top: 1em;
                & button {
                    width: 100%;
                }
            }
        }
    }
`

const Centered = styled.div`
    text-align: center;
    align-self: center;
    & h2,
    p {
        margin: 0;
    }

    & h2 {
        margin-bottom: 10px;
    }

    & p {
        opacity: 0.8;
        font-size: 15px;
    }

    & > .column-center {
        gap: 25px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 2rem;
        & h2 {
            font-size: 1.3rem;
        }
    }
`
