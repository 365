import { getWhoAmI } from '../../infrastructure/api-activation'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

export const useWhoAmI = ({ email, onError }: Options) => {
    const navigate = useNavigate()
    const { executeRecaptcha } = useGoogleReCaptcha()
    const queryKey = ['Who am I', email]

    const { data: iAm, ...rest } = useQuery({
        queryKey,
        queryFn: async () => {
            try {
                const captcha_token = await executeRecaptcha?.('useWhoAmI')
                const whoIAm = await getWhoAmI({
                    email: `${email}`,
                    captcha_token: `${captcha_token}`,
                })
                navigate(whoIAm.goTo)
                return whoIAm
            } catch (error: any) {
                onError?.(error)
            }
        },
        enabled: false,
        retry: false,
    })

    return { iAm, ...rest }
}

type Options = {
    email?: string
    onError?: (message: string) => void
}
