import { useEffect, useState } from 'react'
import styled from 'styled-components'
import LoadingPage from './static/LoadingPage'
import { useNavigate } from 'react-router-dom'
import { GACategory, SendGAEvent } from '../helpers/GAEvents'
import { useUser } from '../hooks/queries/useUser'

type Props = {}

const LinkLogin = (props: Props) => {
    const { userLogout } = useUser()
    const [ready, setReady] = useState(true)
    const [error, setError] = useState<undefined | string>(undefined)
    const navigate = useNavigate()

    const redirect = () => {
        setTimeout(() => {
            navigate('/')
        }, 4000)
    }

    useEffect(() => {
        getCodeFromQuery()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCodeFromQuery = async () => {
        const queryParams = new URLSearchParams(window.location.search)
        const code = queryParams.get('code')
        if (code) {
            try {
                // second params is to force handle login to use the endpoint valid for magic link codes
                // await handleLogin(code, true)
            } catch (error) {
                console.log('Catch line 34 Link Login')
                userLogout()
                setReady(false)
                SendGAEvent(GACategory.ERROR, `Invalid magic link: Invalid code`)
                setError('Link de acceso inválido. Por favor ingresa con tu email.')
                redirect()
            }
        } else {
            console.log('Catch line 42 Link Login')
            setReady(false)
            SendGAEvent(GACategory.ERROR, `Invalid magic link: Has no code`)
            setError('No existe un código de autenticación. Por favor ingresa con tu email.')
            redirect()
        }
    }

    if (!ready) {
        return (
            <MessageContainer>
                <h4>{error}</h4>
            </MessageContainer>
        )
    }

    return <LoadingPage />
}

export default LinkLogin

const MessageContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
