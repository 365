import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes/defaultTheme'

export const CardWithCheck = ({
    checked = false,
    onClick,
    header,
    main,
    footer,
    styles,
}: CardProps) => (
    <Container checked={checked} onClick={onClick} style={styles?.card}>
        <header className="header" style={styles?.header}>
            {header && <div>{header}</div>}
            <div className="check">
                <FontAwesomeIcon
                    icon="circle-check"
                    style={{
                        fontSize: 20,
                        color: checked ? '#00A3FF' : '#dedede',
                        paddingLeft: '0.5em',
                    }}
                />
            </div>
        </header>
        {main && <main style={styles?.main}>{main}</main>}
        {footer && <footer style={styles?.footer}>{footer}</footer>}
    </Container>
)

export const Card = ({ header, main, footer, styles, onClick }: CardProps) => (
    <Container onClick={onClick} style={styles?.card} checked={false}>
        {header && <header style={styles?.header}>{header}</header>}
        {main && <main style={styles?.main}>{main}</main>}
        {footer && <footer style={styles?.footer}>{footer}</footer>}
    </Container>
)

export type CardProps = {
    header?: React.ReactNode
    main?: React.ReactNode
    footer?: React.ReactNode
    checked?: boolean
    onClick?: React.MouseEventHandler<HTMLDivElement>
    styles?: {
        card?: React.CSSProperties
        header?: React.CSSProperties
        main?: React.CSSProperties
        footer?: React.CSSProperties
    }
}

const Container = styled.div<{ checked: boolean }>`
    border: 1px solid #dedede;
    border-radius: ${defaultTheme.borderRadius};
    border: ${({ checked: active }) => (active ? '2px solid #00A3FF' : '1px solid #dedede')};

    header,
    main,
    footer {
        padding: 2em;
    }

    &.active {
        border: '3px solid #00A3FF';
    }

    .header {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto;
        align-items: center;
    }
`
