import styled from 'styled-components'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { CourseNoStartedYet } from './CourseNoStartedYet'
import { MyTeamList } from './MyTeamList'
import { MyTeamOnAsyncCourses } from './MyTeamOnAsyncCourses'

export const MyTeam = () => {
    const { currentCourse } = useUserCourses()

    if (!currentCourse) return null

    const { status, alreadyAssigned, asyncCourse } = currentCourse

    if (asyncCourse) return <MyTeamOnAsyncCourses />

    if (status === 'enrolled')
        return (
            <MyTeamContainer>
                <CourseNoStartedYet />
            </MyTeamContainer>
        )

    if (!alreadyAssigned) return <MyTeamContainer className="hide" />
    if (currentCourse)
        return (
            <MyTeamContainer>
                <MyTeamList />
            </MyTeamContainer>
        )

    return null
}

const MyTeamContainer = styled.section`
    min-height: 100dvh;
    width: 240px;
    padding: 1em;
    background-color: white;
    transition: 0.3s cubic-bezier(0, 0.55, 0.45, 1) 0s;

    &.hide {
        width: 0px;
        padding: 0;
    }

    &.center {
        display: grid;
        place-content: center;
        text-align: center;
    }
`
