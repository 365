import React from 'react'
import { BlurStyled } from './BlurStyled'

interface Props {
    children?: React.ReactNode[] | React.ReactNode ,
    zIndex?: number,
    className?: string | undefined
}

export const Blur = ({children, zIndex = 1, className}: Props) => {
  return (
    <BlurStyled className={className} zIndex={zIndex}></BlurStyled>
  )
}
