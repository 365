import { FocusEvent, ReactNode, useEffect, useId, useState } from 'react'
import { IconName, IconPrefix } from '@fortawesome/pro-regular-svg-icons'

import { API_AXIOS_ERROR } from '../../interfaces/api-activation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import styled from 'styled-components'

export const Input = ({
    label,
    labelProps,
    error: propError,
    api_error,
    icon,
    iconPrefix,
    ...rest
}: InputProps) => {
    const [error, setError] = useState<string | undefined>(propError)
    const id = useId()

    useEffect(() => {
        if (!api_error) return
        const inputError = (api_error as API_AXIOS_ERROR)?.response?.data?.errors?.find(
            ({ key, message }) => key === rest.name && message
        )?.message
        setError(inputError)
    }, [api_error, rest.name])

    const onBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
        const {
            target: {
                validationMessage,
                validity: { valid },
            },
        } = event
        !valid ? setError(validationMessage) : setError(propError)
        rest?.onBlur?.(event)
    }

    useEffect(() => {
        setError(propError)
    }, [propError])

    return (
        <Styles>
            <label {...labelProps}>
                {label}
                {icon && iconPrefix ? (
                    <>
                        <FontAwesomeIcon icon={[iconPrefix, icon]} className="input-icon" />
                        <input
                            type="text"
                            name="name"
                            id={`name-${id}`}
                            className="input-field"
                            {...rest}
                        />
                    </>
                ) : (
                    <input type="text" name="name" id="name" {...rest} onBlur={onBlur} />
                )}
                {error && <div className="error">{error}</div>}
            </label>
        </Styles>
    )
}

export interface InputProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: ReactNode
    labelProps?: React.DetailedHTMLProps<
        React.LabelHTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
    >
    error?: string
    api_error?: unknown
    iconPrefix?: IconPrefix
    icon?: IconName
}

const Styles = styled('div')`
    label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }
    input:invalid {
        border: 2px solid ${defaultTheme.color.errors};
    }
    .error {
        color: ${defaultTheme.color.errors};
    }

    .input-icon {
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        padding-left: 10px;
    }

    .input-field {
        width: 100%;
        padding: 10px 10px 10px 40px;
    }
`
