import { useTranslation } from 'react-i18next'
import { Steps } from './Enroll'
import styled from 'styled-components'

type IProps = {
    onContinue: () => void
    onBack?: () => void
    step: Steps
    courseId?: string
    cohortId?: string
    iSCreating?: boolean
    isError?: boolean
    error?: Error | null
    errorMessage?: string
}

export const ButtonsBlock = ({
    onContinue,
    isError,
    iSCreating,
    step,
    courseId,
    cohortId,
    onBack,
    error,
    errorMessage,
}: IProps) => {
    const { t } = useTranslation('enroll')
    return (
        <ButtonsBlockStyle className="buttons column">
            <button
                className="primary"
                onClick={onContinue}
                disabled={
                    isError ||
                    iSCreating ||
                    (step === 'frequency' && !courseId) ||
                    (step === 'cohorts' && !cohortId)
                }
            >
                {t('Continue')}
            </button>
            {step !== 'frequency' && step !== 'async' && (
                <button className="text" onClick={onBack}>
                    {t('Back')}
                </button>
            )}
            {!!error && <div className="error">{errorMessage}</div>}
        </ButtonsBlockStyle>
    )
}

const ButtonsBlockStyle = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    & button {
        margin-left: 0 !important;
    }
`
