import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ResponsiveLayout } from '../../layouts/ResponsiveLayout'
import { CourseDetails } from './components/CourseDetails'
import { Enroll } from './components/Enroll'
import { useDetails } from './hooks/useDetails'
import LoadingPage from '../static/LoadingPage'
import { Footer } from '../../components/Footer'
import styled from 'styled-components'

export const CourseDetailsLayout = () => {
    const { slug } = useParams()
    const { t } = useTranslation('courseDetails')

    const { details, isPending } = useDetails(slug)

    if (isPending) return <LoadingPage />

    return (
        <ResponsiveLayout
            onlyDesktop={true}
            header={null}
            content={
                details ? <CourseDetails /> : <p>{t('The requested route does not exist.')}</p>
            }
            aside={
                details ? (
                    <EnrollStyles>
                        <div className="aside-content">
                            <Enroll isWidget={true} />
                        </div>
                    </EnrollStyles>
                ) : null
            }
            footer={<Footer />}
        />
    )
}

const EnrollStyles = styled.section`
    min-height: 100vh;
    margin-right: 3.5vw;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    .aside-content {
        width: 400px;
        min-height: 80vh;
        background-color: white;
        box-shadow: rgba(0, 55, 80, 0.1) 0px 0px 25px;
        border-radius: 20px;
        padding: 2em;
        display: grid;
        justify-items: center;
        -webkit-box-align: center;
        align-items: center;
        ::-webkit-scrollbar {
            width: 0px;
        }
    }

    .main-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2em;
    }

    & > div {
        .title {
            font-size: 1.5em;
            font-weight: 700;
            margin: 0;
        }
        & > div {
            grid-template-columns: auto;
            grid-auto-columns: auto;
            place-content: unset;
            justify-items: unset;
            & > div {
                width: 100%;
                grid-template-columns: auto;
            }
        }
    }
`
