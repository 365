import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { Tag } from '../../pages/Home/component/component/CourseCard'
import { useStudyPlan } from '../../pages/course/hooks/useStudyPlan'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { AddCalendarButton } from '../Buttons/AddCalendarButton'
import { ContentCard, getStatus } from './ContentCard'
import { MeetButton } from '../CourseCard/MeetButton'
import { useQueryClient } from '@tanstack/react-query'
import { getContentStep } from '../../infrastructure/api-widget'

export const CourseSteps = () => {
    const { t } = useTranslation('dashboard')
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { courseId } = useParams()
    const location = useLocation()
    const [activeSectionId, setActiveSectionId] = useState<string | null>(null)
    const { studyPlan, isPending, todayContent, contentDayDescription } = useStudyPlan(courseId)
    const sectionRef = useRef<HTMLDivElement>(null)
    const [openTodaySection, setOpenTodaySection] = useState<string>()
    const { getCourseData } = useUserCourses()
    const course = getCourseData(courseId)

    const { CourseProgress, progressPercent: percent } = useStudyPlan(courseId)
    const handleOnClick = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
            setOpenTodaySection(todayContent?.section?._id)
        }
    }

    const { plan } = studyPlan || {}
    const { modules = [] } = plan || {}
    const sections = modules.flatMap(module => module.sections)
    const todaySection = sections.find(section => section._id === todayContent?.section?._id)

    const handleStepClick = (stepId: string) => {
        navigate(`/content/step/${stepId}`)
    }

    useEffect(() => {
        const sectionId = new URLSearchParams(location.search).get('sectionId')
        setActiveSectionId(sectionId)
    }, [location.search])

    useEffect(() => {
        modules.flatMap(module =>
            module.sections
                .filter(section => section.abble)
                .flatMap(section => section.steps)
                .forEach(
                    async step =>
                        await queryClient.prefetchQuery({
                            queryKey: ['Content', 'Step', step._id],
                            queryFn: () => getContentStep(step._id),
                        })
                )
        )
    }, [modules, queryClient])

    if (!course) return null
    return (
        <CourseStepsStyled>
            <div className="content-header-container">
                <div className="content-header border-radius">
                    <article className="card custom display-flex">
                        <div>
                            <span className="title">{course.courseName}</span>
                            {!course.asyncCourse && (
                                <div className="progress-bar">
                                    <CourseProgress />
                                    <span>{t('{{ percent }}% completed', { percent })}</span>{' '}
                                </div>
                            )}
                        </div>

                        {course.asyncCourse ? (
                            <div />
                        ) : (
                            <div className="display-flex card-buttons">
                                <AddCalendarButton
                                    buttonLabel={t('Add to calendar')}
                                    className="text custom-button"
                                    iconCalendar={['fas', 'calendar-circle-plus']}
                                />
                                <div>
                                    {course.status === 'finished'
                                        ? t('Your course has ended.')
                                        : ''}
                                </div>
                                <MeetButton {...course} isInside={true} />
                            </div>
                        )}
                    </article>
                    {isPending || !studyPlan || course.status === 'finished' ? null : (
                        <TodayContentCard className="border-radius" onClick={handleOnClick}>
                            <div className="section-card-title">
                                <Tag className="section-card-tag">{t('Today you will see')}</Tag>
                                <h4
                                    className="truncate underline-hover"
                                    onClick={() =>
                                        todayContent.step?._id &&
                                        handleStepClick(todayContent.step?._id)
                                    }
                                >
                                    {contentDayDescription}
                                </h4>
                            </div>
                        </TodayContentCard>
                    )}
                </div>
            </div>
            {isPending ? (
                <p>{t('Downloading the study plan...')}</p>
            ) : !studyPlan ? null : (
                <div className="sections">
                    {sections.map((section, idx) => (
                        <ContentCard
                            key={idx + section._id}
                            content={section}
                            status={getStatus(section, todaySection!)}
                            isTodaySection={todaySection?._id === section._id}
                            sectionRef={sectionRef}
                            openTodaySection={openTodaySection!}
                            setOpenTodaySection={setOpenTodaySection}
                            activeSectionId={activeSectionId!}
                        />
                    ))}
                </div>
            )}
        </CourseStepsStyled>
    )
}

export const CourseStepsStyled = styled.section`
    padding: 0 3rem 6rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.125em;
    @media screen and (max-width: 768px) {
        padding: 0 1rem 6rem 1rem;
    }
    & > .content-header-container {
        z-index: 1;
        display: grid;
        position: sticky;
        top: 0;
        background-color: ${defaultTheme.color.offWhite};
        & > .content-header {
            display: grid;
            grid-gap: 1.125em;
            padding-top: 0.5rem;
            & > article.card {
                min-height: 140px;
                justify-content: space-between;
                & .progress-bar {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;
                    gap: 1ch;
                    text-transform: uppercase;
                }
                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    align-items: normal;
                    .card-buttons {
                        & button.text {
                            order: 2;
                        }
                    }
                }
                @media screen and (max-width: 1024px) {
                    .card-buttons {
                        margin-top: 1rem;
                        flex-direction: column;
                        gap: 0.5rem;
                    }
                }
            }
        }
    }
    & > .sections {
        display: grid;
        gap: 1em;
    }
    .icon {
        font-size: 20px;
    }
`

const TodayContentCard = styled.article`
    width: 100%;
    height: 42px;
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-accent);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%),
        var(--color-accent);
    color: ${defaultTheme.color.white};
    &:hover {
        cursor: pointer;
    }

    & > .section-card-title {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        column-gap: 1em;
        & > .section-card-tag {
            text-transform: uppercase;
        }
        & > h4 {
            font-size: 1.14em;
            font-weight: 600;
            color: ${defaultTheme.color.primary};
            margin: 0;
        }
    }
    & > .meet-button > button {
        height: 42px;
    }
    @media screen and (max-width: 768px) {
        display: flex;
        padding: 2em;
        height: 76.5px;
        & > *,
        & button {
            width: 100%;
        }
        & .meet-button {
            display: none;
        }
    }
`
