import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import styled from 'styled-components'
import { PrimaryBtnBlue } from '../../components/Buttons/PrimaryBtn'
import { onDiscordClick } from '../../components/DashModules/DynamicDeck'
import ModalPageLayout from '../../layouts/ModalPageLayout'

const InfoDiscordPage = () => {
  return (
      <ModalPageLayout>
          <h1>Comunidad Egg</h1>
          <h2>Si aún no has utilizado Discord, observa este tutorial que te explica cómo unirte:</h2>
          <LiteYouTubeEmbed id="8SU8pouVZJc" title="Tutorial de acceso a Discord | LXP | Comunidad Egg" playerClass="lty-playbtn" wrapperClass="yt-lite" />
          <ContentActions>
              <PrimaryBtnBlue onClick={() => onDiscordClick()}>Unirme a Discord</PrimaryBtnBlue>
          </ContentActions>
      </ModalPageLayout>
  )
}

export default InfoDiscordPage


const ContentActions = styled.div`
    margin-top: 28px;
    display: flex;
    justify-content: end;
`