import React from 'react'
import { Video } from '../Video'
import styled from 'styled-components'
import { useStudyPlan } from '../../pages/course/hooks/useStudyPlan'
import { useTranslation } from 'react-i18next'
import { useUserCourses } from '../../hooks/queries/useUserCourses'

export const MyTeamOnAsyncCourses = () => {
    const { t } = useTranslation('widget')
    const { currentCourse } = useUserCourses()
    const { ContentDayLink } = useStudyPlan(currentCourse?.courseId)
    return (
        <AsyncStyle className="column-center">
            <Video
                SpanishUrl="https://youtube.com/shorts/07RhRp2ZOcw"
                EnglishUrl="https://youtube.com/shorts/ZSP5DXkyvf0"
                width={203}
                style={{
                    borderRadius: 6,
                    overflow: 'hidden',
                    maxHeight: '55vh',
                }}
                controls={false}
            />

            <ContentDayLink className="primary w-100 only-desktop">{t('Start now')}</ContentDayLink>
        </AsyncStyle>
    )
}

const AsyncStyle = styled.div`
    width: 240px;
    padding: 16px;
    gap: 5em;
    text-align: center;
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        & > * {
            border-radius: 0 !important;
            width: 100% !important;
            height: 100% !important;
        }
    }
`
