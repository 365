import { useQuery } from '@tanstack/react-query'
import { getCourseDetails } from '../api-marketplace'

export const useDetails = (slug?: string) => {
    const queryKey = ['Course', slug, 'Details']
    const queryFn = () => getCourseDetails(`${slug}`)

    const { data: details, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: !!slug,
    })

    return { details, ...rest }
}
