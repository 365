import { FormEvent, useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Input } from '../../../components/FormElements/Input'
import { signInWithCredentials } from '../../../infrastructure/api-activation'

export const LoginWithCredential = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { t } = useTranslation('signIn')
    const navigate = useNavigate()

    let [searchParams] = useSearchParams()
    const emailQuery = searchParams.get('email')
    const [email, setEmail] = useState(emailQuery ?? '')

    const [password, setPassword] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>()

    const onSubmit = useCallback(
        async (event?: FormEvent<HTMLFormElement>) => {
            event?.preventDefault()
            if (!executeRecaptcha) return
            if (!email || !password) return setErrorMessage(t('Please fill in all fields'))

            try {
                const captcha_token = await executeRecaptcha('signInWithCredentials')
                const response = await signInWithCredentials({ email, password, captcha_token })
                const accessToken = response['access-token']
                if (!accessToken) return setErrorMessage(t('Wrong password'))

                return navigate(`/auth/${accessToken}`)
            } catch (error) {
                setErrorMessage(t('Wrong password'))
            }
        },
        [email, executeRecaptcha, navigate, password, t]
    )

    return (
        <>
            <form onSubmit={onSubmit}>
                <h1 className="title">{t('Enter your password')}</h1>
                <p className="light center">
                    {t(
                        `You are one step away from immersing yourself in Egg's cooperative learning experience.`
                    )}
                </p>
                <br />
                <Input
                    {...{
                        label: t('Email'),
                        id: 'email',
                        name: 'email',
                        type: 'email',
                        placeholder: t('Email'),
                        value: email ?? '',
                        autoComplete: 'username',
                        onChange: ({ target: { value } }) => {
                            setErrorMessage('')
                            setEmail(value)
                        },
                    }}
                />
                <br />

                <Input
                    {...{
                        label: t('Password'),
                        id: 'password',
                        name: 'password',
                        type: 'password',
                        placeholder: t('Password'),
                        autoComplete: 'current-password',
                        value: password,
                        onChange: ({ target: { value } }) => setPassword(`${value}`),
                        error: errorMessage,
                    }}
                />
                <br />
                <div className="buttons column">
                    <button
                        className="primary"
                        type="submit"
                        children={t('Log in')}
                        disabled={!email || !password}
                    />
                    <button
                        className="outlined"
                        onClick={() => email && navigate(`../password-reset/${email}`)}
                        children={t('I forgot my password')}
                        disabled={!email}
                    />
                    <button
                        className="outlined"
                        children={t(`Cancel`)}
                        onClick={() => navigate('/login')}
                    />
                </div>
            </form>
        </>
    )
}

export default LoginWithCredential
