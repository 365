import { Outlet, useNavigate, useParams } from 'react-router-dom'

import LoadingPage from '../pages/static/LoadingPage'
import { ResponsiveLayout } from './ResponsiveLayout'
import { useUserCourses } from '../hooks/queries/useUserCourses'
import { MyTeam } from '../components/MyTeam/MyTeam'
import { MobileFooterBar } from '../components/NavBar/MobileFooterBar'
import { useResponsive } from '../hooks/useResponsive'
import { useState } from 'react'

const AppLayout = () => {
    const navigate = useNavigate()
    const { courseId } = useParams()
    const { isLoading, courses } = useUserCourses()

    const course = courses?.find(course => course.courseId === courseId)
    const requirePayment = course?.requirePayment
    if (requirePayment) navigate(`marketplace/${course.slug}/payment`)

    if (isLoading) return <LoadingPage />

    return (
        <ResponsiveLayout
            header={null}
            content={<Outlet />}
            aside={<Footer />}
            footer={null}
            allowScroll={false}
        />
    )
}

const Footer = () => {
    const [showWidget, setShowWidget] = useState(false)
    const { isDesktop } = useResponsive()
    return isDesktop ? (
        <MyTeam />
    ) : (
        <MobileFooterBar showWidget={showWidget} setShowWidget={setShowWidget} />
    )
}

export default AppLayout
