import { useTranslation } from 'react-i18next'
import { useCohorts } from '../hooks/useCohorts'
import { EnrollProps } from './Enroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardWithCheck } from '../../../components/CardWithCheck'
import { CohortDTO } from '../api-marketplace'
import { useParams } from 'react-router-dom'
import { ButtonsBlock } from './ButtonsBlock'
import { parseDateToDDDD_D_de_MMMM_HH } from '../../../helpers/dateFormatters'

export const CohortsView = (props: EnrollProps) => {
    const { slug } = useParams()
    const { t } = useTranslation('enroll')
    const [{ courseId, cohortId }, setData] = props.choices
    const { cohorts, isLoading } = useCohorts({ slug, courseId })

    return (
        <>
            <main>
                <strong className="blue">
                    {t('Step {{ step }} of {{ steps }}', {
                        step: 2,
                        steps: 2,
                    })}
                </strong>
                <h1 className="title">{t('Select your start date')}</h1>
                <p className="light">
                    {t(
                        `We recommend choosing the closest date. You've already made up your mind, and there's no reason to postpone it.`
                    )}
                </p>
                <br />
                {isLoading && <p>{t('Looking for the upcoming available dates...')}</p>}

                {cohorts?.map(cohort => (
                    <CohortCard
                        {...cohort}
                        key={cohort.id}
                        selected={cohortId}
                        onSelect={id => setData(prev => ({ ...prev, cohortId: id }))}
                    />
                ))}
            </main>
            <ButtonsBlock {...{ ...props, step: 'cohorts' }} />
        </>
    )
}

const CohortCard = ({
    id,
    date,
    slots,
    fewSlots,
    onSelect,
    selected: cohortSelected,
}: CohortCardProps) => {
    const { t } = useTranslation('enroll')
    const soldOut = !slots
    return (
        <>
            <CardWithCheck
                styles={{
                    card: {
                        cursor: 'pointer',
                        color: soldOut ? '#c7c7c7' : 'inherit',
                    },
                }}
                onClick={() => !soldOut && onSelect(id)}
                checked={id === cohortSelected}
                header={
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={['far', 'calendar']} style={{ fontSize: 22 }} />
                        <div>
                            <div style={{ fontWeight: 600 }}>
                                {parseDateToDDDD_D_de_MMMM_HH(date)}
                            </div>

                            {fewSlots && (
                                <small
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 10,
                                        color: '#00A3FF',
                                        fontWeight: 600,
                                    }}
                                >
                                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                                    {t('Few places available!')}
                                </small>
                            )}
                            {soldOut && (
                                <small
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 10,
                                        color: '#ff647c',
                                        fontWeight: 600,
                                    }}
                                >
                                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                                    {t('Sold out!')}
                                </small>
                            )}
                        </div>
                    </div>
                }
            />
            <br />
        </>
    )
}

type CohortCardProps = CohortDTO & {
    onSelect: (id: string) => void
    selected: string | undefined
    fewSlots?: boolean
}
