import { capitalize, capitalizeAll } from '../helpers/formatters'

import { UserContext } from '../context/UserContext'
import axios from 'axios'
import dayjs from 'dayjs'
import { useActiveCourse } from '../pages/course/hooks/useActiveCourse'
import { useContext } from 'react'

export type Certificate = {
    _id: string
    title: string
    _profileId: string
    name: string
    lastname: string
    course: string
    schedule: string
    idd: string
    content: string
    issueDate: string
    trayecto: string
    unidad_academica: string
    template: string
    url: string
    updatedAt: string
}

type Certificates = {
    certificates: Certificate[]
}

export const useCertificates = () => {
    const { activeCourse } = useActiveCourse()
    const { user } = useContext(UserContext)

    const getRegularStudentCert = async (schedule?: string) => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_CERTIFICATES}/certs?userId=${
                    user?.profileId
                }&name=${capitalize(user?.name)} ${capitalize(user?.lastname)}&course=${
                    activeCourse?.courseName
                }&schedule=${schedule}&content=${''}&date=${dayjs().format('LL')}`
            )
            return res
        } catch (error) {
            console.log('err', error)
        } finally {
        }
    }

    const getCertificatePdf = (certificate: Certificate) =>
        axios
            .get(`${process.env.REACT_APP_CERTIFICATES}/certs/pdf?${getQueryParams(certificate)}`)
            .then(res => res?.data.body.data)

    const getCertificatesList = () =>
        axios
            .get<Certificates>(`${process.env.REACT_APP_WIDGET_API}/v1/certificates`)
            .then(({ data }) => data.certificates)
            .catch(() => [])

    const getLinkedInCertURL = (certificate: Certificate) =>
        axios
            .get<GetLinkedInURL>(
                `${process.env.REACT_APP_CERTIFICATES}/certs/toS3?${getQueryParams(certificate)}`
            )
            .then(({ data }) => parseLinkedInCertURL(certificate, data.body.data))

    return {
        getRegularStudentCert,
        getCertificatesList,
        getCertificatePdf,
        getLinkedInCertURL,
    }
}

const parseLinkedInCertURL = ({ _id, title, updatedAt }: Certificate, url: string) =>
    `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodeURIComponent(
        capitalizeAll(title)
    )}&issueMonth=${dayjs(updatedAt).month() + 1}&issueYear=${dayjs(
        updatedAt
    ).year()}&certId=${_id}&certUrl=${encodeURIComponent(
        url
    )}&organizationId=22343212&trk=login_reg_redirect`

const getQueryParams = (certificate: Certificate) =>
    new URLSearchParams({
        profileId: certificate._profileId,
        name: certificate.name,
        lastname: certificate.lastname,
        course: certificate.course,
        idd: certificate.idd,
        content: certificate.content,
        date: dayjs(certificate.issueDate).locale('es').format('LL'),
        trayecto: certificate.trayecto,
        unidad_academica: certificate.unidad_academica,
        template: certificate.template || 'template_d',
        schedule: certificate.schedule,
    }).toString()

interface GetLinkedInURL {
    body: { data: string }
}
