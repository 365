import i18n from '../i18n'

export const toCurrency = (value: number) => {
    return new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    }).format(value)
}

export function toPhoneNumber(phone: string) {
    const cleaned = `${phone}`.replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone
}

export const addAndToLastItem = (index: number, array: unknown[]) => {
    if (index === array.length - 1) {
        return ''
    } else if (index === array.length - 2) {
        return i18n.t(' and ')
    } else {
        return ', '
    }
}

export const arrayToList = (array: unknown[], lastSeparator = ' and ', restSeparator = ', ') =>
    array
        .map((item, index, array) => {
            const div =
                index === array.length - 1
                    ? ''
                    : index === array.length - 2
                    ? lastSeparator
                    : restSeparator
            return `${item}${div}`
        })
        .join('')

export const dataURItoBlob = (dataURI: string) => {
    const byteString = window.atob(dataURI)
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const int8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' })
    return blob
}

export const capitalize = (text?: string) => `${text}`.trim().replace(/^\w/, c => c.toUpperCase())
export const capitalizeAll = (text?: string) => `${text}`.split(' ').map(capitalize).join(' ')
export const capitalizeAndTrim = (text?: string) => {
    const capitalized = `${text}`.split(' ').map(capitalize).join(' ')
    return capitalized.length > 15 ? `${capitalized.slice(0, 15)}...` : capitalized
}

export const numberFormatted = (number: number) =>
    new Intl.NumberFormat(navigator.language, {}).format(number)
