import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    parseDateToDDDD_D_de_MMMM,
    parseDateToDDDD_D_de_MMMM_HH,
} from '../../helpers/dateFormatters'
import { useGoogle } from '../../hooks/useGoogle'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { useActiveCourse } from '../../pages/course/hooks/useActiveCourse'

export const CourseNoStartedYet = () => {
    const { t } = useTranslation('widget')
    const { currentCourse } = useUserCourses()
    const { activeCourse } = useActiveCourse()
    const { addToCalendarUrl } = useGoogle(currentCourse?.profileId)

    const startDate = parseDateToDDDD_D_de_MMMM_HH(`${currentCourse?.start}`)
    const startDateWthHour = parseDateToDDDD_D_de_MMMM(`${currentCourse?.start}`)

    return (
        <NoStatedStyle className="column-center">
            <div>
                <h2 className="widget-title">{t('Your course has not started yet')}</h2>

                {!activeCourse?.asyncCourse ? (
                    <p className="widget-subtitle light">
                        {t(
                            'You will be able to enter the virtual meeting from {{ startDate }} hours.',
                            { startDate }
                        )}
                    </p>
                ) : (
                    <>
                        <p className="widget-subtitle light">
                            {t('You can start from {{ startDate }}.', { startDateWthHour })}
                        </p>
                        <p className="widget-subtitle light">
                            {t('Stay tuned for the communications you will receive by email.')}
                        </p>
                    </>
                )}
            </div>

            {addToCalendarUrl && (
                <a
                    href={addToCalendarUrl ?? ''}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link calendar-link"
                >
                    <FontAwesomeIcon icon={['fas', 'calendar-check']} size="4x" />
                    <p className="widget-subtitle ">
                        {t("Add this event to your calendar so you don't miss the day")}
                    </p>
                </a>
            )}
        </NoStatedStyle>
    )
}

const NoStatedStyle = styled.div`
    min-height: inherit;
    text-align: center;
    padding: 0 1em;

    .calendar-link {
        width: 90%;
        display: flex;
        align-items: center;
        gap: 1.5em;
        text-align: justify;
    }
`
