import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes/defaultTheme'

type IProps = {
    children: ReactNode
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    disabled?: boolean
    mode: 'light' | 'dark'
} & React.HTMLAttributes<HTMLButtonElement>

const BtnSquare = ({
    children,
    onClick,
    onMouseEnter,
    onMouseLeave,
    disabled = false,
    mode,
    ...rest
}: IProps) => {
    return (
        <BtnSquareStyled
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            disabled={disabled}
            className={mode}
            {...rest}
        >
            {children}
        </BtnSquareStyled>
    )
}

export default BtnSquare

const BtnSquareStyled = styled.button`
    border: 1px solid ${defaultTheme.color.lightGray};
    border-radius: 10px;
    font-size: 15px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0 4px;
    cursor: pointer;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    &.light {
        color: ${defaultTheme.color.primary};
        background: ${defaultTheme.color.white};
    }
    &.dark {
        color: ${defaultTheme.color.white};
        background: ${defaultTheme.color.primary};
        width: 9em;
        height: 2.5em;
        margin: 0;
        padding: 0;
        & > .icon {
            width: 1em;
            height: 1.3em;
        }
    }
    &.light:hover {
        background-color: ${defaultTheme.color.lightGray};
    }
    &.dark:hover {
        color: ${defaultTheme.color.white};
        background-color: ${defaultTheme.color.electricBlue};
        border: 1px solid ${defaultTheme.color.electricBlue};
    }
    @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
        font-size: 13px;
    }
`
