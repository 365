import AppLayout from '../layouts/AppLayout'
import Certificates from '../pages/certificates/Certificates'
import CloseSession from '../pages/login/components/CloseSession'
import ContentBoard from '../pages/ContentBoard'
import Dashboard from '../pages/Dashboard'
import LinkLogin from '../pages/LinkLogin'
import LoginWithCode from '../pages/login/components/LoginWithCode'
import LoginWithCredential from '../pages/login/components/LoginWithCredential'
import PrivateRoute from './PrivateRoute'
import Referrals from '../pages/Referrals'
import UserContextProvider from '../context/UserContext'
import UserProfile from '../pages/UserProfile'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { CourseDetailsLayout } from '../pages/marketplace/CourseDetailsLayout'
import { DynamicContent } from '../pages/DynamicContent'
import { Ecosystem } from '../pages/ecosystem/Ecosystem'
import { EmailValidation } from '../pages/register/components/EmailValidation'
import { Enroll } from '../pages/marketplace/components/Enroll'
import { GeneralLayout, HelloCenterLayout } from '../pages/Home/HomeLayout'
import { Help } from '../pages/Help/Help'
import { Home } from '../pages/Home/component/Home'
import { LoginFromAdmin } from '../pages/login/components/LoginFromAdmin'
import { LoginLayout } from '../layouts/LoginLayout'
import { LoginMethods } from '../pages/login/LoginMethods'
import { MarketPlaceLayout } from '../pages/marketplace/MarketPlaceLayout'
import { Migration } from '../pages/register/components/Migration'
import { PasswordReset } from '../pages/login/components/PasswordReset/PasswordReset'
import { Payment } from '../pages/marketplace/components/Payment'
import { RankingView } from '../pages/ranking/components/RankingView'
import { Redirect } from '../pages/Redirect'
import { RegisterError } from '../pages/register/components/RegisterError'
import { RegisterLayout } from '../pages/register/RegisterLayout'
import { SignUpStep1 } from '../pages/register/components/SignUpStep1'
import { SignUpStep2 } from '../pages/register/components/SignUpStep2'
import { AuthWithAccessToken } from '../pages/login/components/AuthWithAccessToken'

export const AppRoutes = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" index element={<Redirect />} />

            <Route path="ranking" element={<GeneralLayout />}>
                <Route index element={<RankingView />} />
                <Route path=":course" element={<RankingView />} />
            </Route>

            <Route path="ecosystem" element={<GeneralLayout />}>
                <Route index element={<Ecosystem />} />
            </Route>

            <Route element={<HelloCenterLayout />}>
                <Route path="home" element={<Home />} />
            </Route>

            <Route element={<GeneralLayout />}>
                <Route path="help" element={<Help />} />
                <Route path="me" element={<UserProfile />} />
            </Route>

            <Route element={<AppContextsWrapper />}>
                <Route path="/referrals" element={<Referrals />} />
                <Route path="/certificates" element={<Certificates />} />
                <Route element={<AppLayout />}>
                    <Route index element={<Redirect />} />

                    <Route path="/dashboard/:courseId" element={<Dashboard />} />
                    <Route path="/contentBoard" element={<ContentBoard />} />
                    <Route path="/content/:slug" element={<DynamicContent />} />
                    <Route path="/content/step/:stepId" element={<ContentBoard />} />
                </Route>
            </Route>

            <Route path="/auth/:token" element={<AuthWithAccessToken />} />

            <Route path="/login" element={<LoginLayout />}>
                <Route index element={<LoginMethods />} />
                <Route path="admin" element={<LoginFromAdmin />} />
                <Route path="link-login" element={<LinkLogin />} />
                <Route path="code/*" element={<LoginWithCode />} />
                <Route path="credential/*" element={<LoginWithCredential />} />
                <Route path="close/*" element={<CloseSession />} />
                <Route path="password-reset/:emailOrToken" element={<PasswordReset />} />
                <Route path="*" element={<LoginMethods />} />
            </Route>

            <Route path="/registro" element={<RegisterLayout />}>
                <Route index element={<SignUpStep1 />} />
                <Route path="1" element={<SignUpStep1 />} />
                <Route path="2" element={<SignUpStep2 />} />
                <Route path="validar" element={<EmailValidation />} />
                <Route path="error" element={<RegisterError />} />
                <Route path="actualizar" element={<Migration />} />
            </Route>

            <Route path="/logout" element={<LoginLayout />}>
                <Route index element={<CloseSession />} />
            </Route>

            <Route path="/marketplace/:slug" element={<MarketPlaceLayout />}>
                <Route path="enroll" element={<Enroll />} />
                <Route path="payment" element={<Payment />} />
            </Route>
            <Route path="/way/course/:slug" element={<CourseDetailsLayout />} />

            <Route path="/redirect" element={<Redirect />} />
        </Routes>
    </BrowserRouter>
)

export const AppContextsWrapper = ({ children }: { children?: JSX.Element }) => (
    <UserContextProvider>
        <PrivateRoute>{children ?? <Outlet />}</PrivateRoute>
    </UserContextProvider>
)
