import { API_RESPONSE, DefaultUserDTO, UtmDTO } from '../../interfaces/api-activation'
import { ApiActivation } from '../../infrastructure/api-activation'
import DayJS from '../../helpers/DayJS'
import { setNewAccessToken } from '../../infrastructure/apis'

const tz = DayJS.tz.guess()

export const getUser = () =>
    ApiActivation.get<GetUserResponse>(`/v1/users`).then(({ data }) => data.data)

export const createUser = (data: PostCreateUser, captcha_token: string) =>
    ApiActivation.post<PostUserResponse>('/v1/users', { ...data, tz, captcha_token }).then(
        ({ data }) => {
            const token = data.data['access-token']
            token && setNewAccessToken(token)
            return data.data
        }
    )

export const migrateUser = (data: PostCreateUser) =>
    ApiActivation.post<GetUserResponse>('/v1/users/migrate', { ...data, tz }).then(
        ({ data }) => data.data
    )

export const updateUser = (data: Partial<UserWithReferrer>) =>
    ApiActivation.patch<UpdateUserResponse>('/v1/users', data).then(({ data }) => data.data)

export const updateUserImage = (urlImage: string) =>
    ApiActivation.patch<UpdateUserResponse>('/v1/users/updateImageUrl', { urlImage }).then(
        ({ data }) => data.data
    )

export const getCountries = () =>
    ApiActivation.get<CountriesDTO>('/v1/countries').then(({ data }) => data.data.countries)

export const getProvinces = (countryId: string) =>
    ApiActivation.get<GetProvincesResponse>(`/v1/countries/${countryId}/provinces`).then(
        ({ data }) => data.data.provinces
    )
export const createHubSpotRefer = (data: ReferredDTO) =>
    ApiActivation.post<PostReferResponse>('/v1/users/refer', data)
        .then(({ data }) => ({
            ...data.data,
            valid: true,
        }))
        .catch(() => ({ ...data, valid: false }))

type Referrer = { referrer: Referral['referrer'] }
type UserWithReferrer = DefaultUserDTO & Referrer

type GetUserResponse = API_RESPONSE<UserWithReferrer>
export type PostUser = Partial<UserWithReferrer>

type PostUserResponse = API_RESPONSE<UserWithReferrer>
type UpdateUserResponse = API_RESPONSE<UserWithReferrer>
type CountriesDTO = API_RESPONSE<{ countries: CountryDTO[] }>
type GetProvincesResponse = API_RESPONSE<{ provinces: ProvinceDTO[] }>

export type PostCreateUser = {
    email: string
    name?: string
    lastname?: string
    displayName?: string
    urlImage?: string
    isVerified?: boolean
    termsAndConditions?: boolean
    referrer?: string | null
    password?: string
} & Partial<UtmDTO>

type PostReferResponse = API_RESPONSE<ReferredDTO>

export interface ProvinceDTO {
    id: number
    name: string
    postalCode: string
    countryId: number
}

export interface CountryDTO {
    id: number
    name: string
    iso2: string
    iso3: string
}

export interface ProfileDTO {
    userId: string
    courseId: string
    quotaId?: any
    id: string
    mongoId: string
    mongoScheduleId: string
    active: boolean
    complete: boolean
    createdAt: string
    updatedAt: string
}

export interface ReferredDTO {
    firstname: string
    lastname: string
    email: string
    valid?: boolean
}

type Referral = {
    code: string
    link: string
    referrer: string | null
}
