import { ApiActivation, refreshAccessToken } from './api-activation'
import { ApiHCA } from './api-hca'
import { ApiRanking } from './api-ranking'
import { ApiWidget } from './api-widget'
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { isProduction } from '../interfaces/constants'
import { ApiSocket } from './api-socket'
import { ApiEgg } from './api-egg'
import { decodeToken } from '../helpers/validators'
import DayJS from '../helpers/DayJS'
import { ApiPresent } from './api-present'

export const ERR_500 = 'An Error has occurred, and we are working to fix the problem!'

export const setNewAccessToken = (token: null | string) => {
    if (!token) return removeToken()
    if (`Bearer ${token}` === ApiActivation.defaults.headers.common.Authorization) return

    if (!isProduction) {
        const exp = decodeToken<{ exp: number }>(token)?.exp
        const expIn = exp
            ? DayJS(exp * 1000)
                  .locale('en')
                  .fromNow(true)
            : ''
        console.info(
            `Access token refreshed. New token expire in ${expIn}.`,
            `https://jwt.io/#debugger-io?token=${token}`
        )
    }

    ApiActivation.defaults.headers.common.Authorization = `Bearer ${token}`
    ApiPresent.defaults.headers.common.Authorization = `Bearer ${token}`
    ApiRanking.defaults.headers.common.Authorization = `Bearer ${token}`
    ApiHCA.defaults.headers.common.Authorization = `Bearer ${token}`
    ApiSocket.defaults.headers.common.Authorization = `Bearer ${token}`
    ApiWidget.defaults.headers.common.Authorization = `${token}`
    Axios.defaults.headers.common.Authorization = `${token}`
    ApiEgg.defaults.headers.common['access-token'] = `${token}`

    window.dispatchEvent(new StorageEvent('storage', { key: 'token', newValue: token }))
    localStorage.setItem('token', JSON.stringify(token))
    localStorage.setItem('lxp-token', JSON.stringify(token))
}

let isRefreshing = false
const refreshAndRetryQueue: {
    instance: AxiosInstance
    resolve: (value?: any) => void
    reject: (error?: any) => void
    config: AxiosRequestConfig
}[] = []

export const refreshInterceptor = async (error: any, instance: AxiosInstance) => {
    const originalRequest: AxiosRequestConfig = error.config
    if (error.response && error.response.status === 401) {
        if (!isRefreshing) {
            isRefreshing = true
            try {
                // Refreshing the token
                const newAccessToken = await refreshAccessToken()
                error.config.headers['Authorization'] = `Bearer ${newAccessToken}`
                setNewAccessToken(newAccessToken)

                refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                    ApiActivation.request(config)
                        .then(response => resolve(response))
                        .catch(err => reject(err))
                })
                refreshAndRetryQueue.length = 0
                return ApiActivation(originalRequest)
            } catch (refreshError) {
                // Handle refresh token errors
                console.log(`Authorization failed, redirecting to /logout`)
                window.location.href = '/logout'
                throw refreshError
            } finally {
                // Reset the isRefreshing flag
                isRefreshing = false
            }
        }
        // If the request is already being refreshed, put it in the queue
        return new Promise<void>((resolve, reject) => {
            refreshAndRetryQueue.push({
                instance,
                resolve,
                reject,
                config: originalRequest,
            })
        })
    }
    return Promise.reject(error)
}

const removeToken = () => {
    ApiActivation.defaults.headers.common.Authorization = false
    ApiPresent.defaults.headers.common.Authorization = false
    ApiWidget.defaults.headers.common.Authorization = false
    ApiHCA.defaults.headers.common.Authorization = false
    ApiSocket.defaults.headers.common.Authorization = false
    Axios.defaults.headers.common.Authorization = false
    ApiEgg.defaults.headers.common['access-token'] = false
    localStorage.removeItem('token')
    localStorage.removeItem('lxp-token')
}

window.addEventListener('DOMContentLoaded', event => {
    const token = localStorage.getItem('token')
    token && setNewAccessToken(JSON.parse(token))
})
