import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { useGoogle } from '../../hooks/useGoogle'

export const AddCalendarButton = ({
    buttonLabel,
    className,
    iconCalendar,
    iconChevron,
}: {
    buttonLabel: string
    className: string
    iconCalendar: IconProp
    iconChevron?: IconProp
}) => {
    const { currentCourse } = useUserCourses()
    const { addToCalendarUrl } = useGoogle(currentCourse?.profileId)

    if (!addToCalendarUrl) return null
    const handlerCalendarClick = () => {
        window.open(addToCalendarUrl, '_blank')
    }

    return (
        <button className={className} onClick={handlerCalendarClick}>
            <FontAwesomeIcon icon={iconCalendar} className="action-icon" />
            {buttonLabel}
            {iconChevron && <FontAwesomeIcon icon={iconChevron} className="action-icon" />}
        </button>
    )
}
