import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMyTeam } from '../../hooks/useMyTeam'
import AvatarEgg from '../Misc/AvatarEgg'
import { PulseButton } from './PulseButton'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { ModalType, useUIContext } from '../../context/UIContext'
import { Icon } from '../Icon'
import { capitalizeAndTrim } from '../../helpers/formatters'

export const MyTeamList = () => {
    const { t } = useTranslation('myTeam')
    const { myTeam, status } = useMyTeam()

    const users = myTeam?.table?.positions
    const teamNumber = myTeam?.table?.tableNumber

    if (status === 'pending') return <MyTeamListSkeleton />
    if (status === 'error') return <div>{t('Error loading team data')}</div>

    return (
        <div>
            <header>
                <h2>{t('Team {{ teamNumber }}', { teamNumber })}</h2>
            </header>
            <nav>{<CallToCoachButton />}</nav>
            <main>
                {users?.map(({ _userId, _profileId }) => (
                    <UserItem key={_profileId} {..._userId} _profileId={_profileId} />
                ))}
            </main>
        </div>
    )
}

const MyTeamListSkeleton = () => {
    return (
        <div>
            <br />
            <header className="skeleton">
                <div className="skeleton" style={{ height: 33, width: 104 }} />
            </header>
            <br />
            <main>
                {[1, 2, 3, 4, 5].map(index => (
                    <UserItemSkeleton key={index} />
                ))}
            </main>
        </div>
    )
}

const UserItem = ({ lastname, name, urlImage, _profileId }: UserProps) => {
    const displayName = `${name} ${lastname}`

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '1ch',
                marginBottom: 21,
            }}
        >
            <AvatarEgg
                src={urlImage}
                title={displayName}
                size={{ desktop: 32, mobile: 32 }}
                isExternal={true}
            />
            <span style={{ flexGrow: 2, fontSize: 13 }}>{capitalizeAndTrim(displayName)}</span>

            <PulseButton _profileToId={_profileId} />
        </div>
    )
}

const UserItemSkeleton = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '1ch',
                marginBottom: 21,
            }}
        >
            <div style={{ width: 32, height: 32, borderRadius: '50%' }} className="skeleton" />
            <span className="skeleton" style={{ flexGrow: 2 }}></span>
            <div style={{ width: 32, height: 32 }} className="skeleton" />
        </div>
    )
}

const CallToCoachButton = () => {
    const { t } = useTranslation('myTeam')
    const { dispatchModal } = useUIContext()
    const { myTeam } = useMyTeam()
    const { currentCourse } = useUserCourses()

    const teamNumber = myTeam?.table?.tableNumber
    const enabledActivationTeam = myTeam?.team.settings.enabledActivationTeam
    const ableToCallCoach = enabledActivationTeam && currentCourse?.offlineCourse && teamNumber

    if (!ableToCallCoach) return null

    const openCallToCoachModal = () => {
        if (!teamNumber) return
        dispatchModal({ type: ModalType.CALL_TO_COACH, payload: { tableNumber: teamNumber } })
    }

    return (
        <button
            onClick={openCallToCoachModal}
            title={t('Request external help')}
            className="icon big"
            style={{
                marginBottom: '1.5em',
            }}
        >
            <Icon icon={['fas', 'user-headset']} />
        </button>
    )
}

type UserProps = {
    _profileId: string
    lastname: string
    name: string
    urlImage?: string
}
