import { useTranslation } from 'react-i18next'
import { Select } from './FormElements/Select'
import { useLanguages } from '../hooks/useLanguages'

export const LanguageSelector = ({ labels = 'names', onSelect, label }: LanguageSelectorProps) => {
    const { t } = useTranslation('signUp')
    const { i18n } = useTranslation()
    const { langs, userLang } = useLanguages()

    return (
        <Select
            label={label ?? t('Preferred language')}
            id="language"
            name="language"
            autoComplete="off"
            onChange={({ target: { value } }) => {
                i18n.changeLanguage(value)
                onSelect?.(value)
            }}
            value={userLang.resolvedLanguage}
        >
            {Object.entries(langs ?? {}).map(([key, value]) => (
                <option key={key} value={key}>
                    {labels === 'keys' ? key : value.nativeName}
                </option>
            ))}
        </Select>
    )
}

type LanguageSelectorProps = {
    labels?: 'names' | 'keys'
    onSelect?: (language: string) => void
} & React.ComponentProps<typeof Select>
