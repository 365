import { jwtDecode } from 'jwt-decode'

export const isEmailValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const trimEmail = (email: string) => {
    return email.trim()
}

export function extractYouTubeID(url: string) {
    const regex = /[?&]v=([^&#]*)/
    const match = regex.exec(url)
    return match ? match[1] : null
}

export function testPasswordStrength(password: string, options?: PasswordStrengthOptions) {
    const { length = 6, lowercase = true, uppercase = true, special = true } = options ?? {}

    // Check minimum length
    if (!password || password.length < length)
        return `The password must have a minimum of ${length} characters.`
    if (lowercase && !/[a-z]/.test(password)) return 'The password must contain lowercase letters.'
    if (uppercase && !/[A-Z]/.test(password)) return 'The password must contain uppercase letters.'
    if (special && !/[$@#&!.]/.test(password))
        return 'The password must contain special characters. ($, @, #, &, !, or .)'

    // The password is secure
    return true
}

export const decodeToken = <T>(token: string) => {
    try {
        return jwtDecode<T>(token)
    } catch (error) {
        return null
    }
}

export const isTokenValid = (token: string) => {
    try {
        const { exp } = jwtDecode<{ exp: number }>(token)
        return exp * 1000 > Date.now()
    } catch (error) {
        return false
    }
}

export const isTokenExpired = (token: string) => !isTokenValid(token)

interface PasswordStrengthOptions {
    length?: number
    lowercase?: boolean
    uppercase?: boolean
    special?: boolean
}
