import Axios from 'axios'
import { decodeToken } from '../helpers/validators'
import { refreshInterceptor } from './apis'

export const ApiHCA = Axios.create({
    baseURL: process.env.REACT_APP_HCA_API,
})

ApiHCA.interceptors.response.use(
    ok => ok,
    error => refreshInterceptor(error, ApiHCA)
)

export const getMeetingTokens = (algorithm_id?: string | null) =>
    ApiHCA.post<GetTokensDTO>('/sdk_session/get_sdk_tokens', {
        algorithm_id: algorithm_id ?? undefined,
    }).then(({ data }) => {
        const general = {
            ...data.general_room,
            ...(data.general_room?.token
                ? decodeToken<MeetTokenPayload>(data.general_room.token)
                : {}),
        }

        const teams = !data.teams_room
            ? undefined
            : {
                  ...data.teams_room,
                  ...(data.teams_room?.token
                      ? decodeToken<MeetTokenPayload>(data.teams_room.token)
                      : {}),
              }

        return { general, teams }
    })

interface GetTokensDTO {
    general_room?: {
        // Undefined if the course's settings are not set to use the general room
        token?: string
        url: string
    }
    teams_room?: {
        // Undefined if there is a not valid algorithm_id
        token?: string
        table_number: number
        url: string
    }
}

type MeetTokenPayload = {
    app_key: string
    tpc: string
    role_type: number
    session_key: string
    user_identity: string
    version: number
    iat: number
    exp: number
    room: 'team' | 'general'
}
