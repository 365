import React from 'react'
import { Modal } from './Modal'
import { useIntercom } from '../../hooks/useIntercom'
import { useUIContext } from '../../context/UIContext'
import { Trans, useTranslation } from 'react-i18next'

export const ConflictWarning = ({ onRegister }: { onRegister?: () => void }) => {
    const { t } = useTranslation('enroll')
    const { chatWithSales } = useIntercom()
    const { modalClose } = useUIContext()

    return (
        <Modal>
            <div style={{ maxWidth: '70ch' }}>
                <h2>{t(`Almost there!`)}</h2>

                <p>{t('Before enrolling in the course, please consider the following:')}</p>

                <ul style={{ color: 'inherit' }}>
                    <li>
                        <p>
                            {t(
                                'Choose a date and pace, ensuring that if you are currently taking another course, the chosen date is after your completion date.'
                            )}
                        </p>
                    </li>
                    <li>
                        <p>
                            {t(
                                "Verify that you have completed the necessary prerequisite courses for the level of the course you're about to start."
                            )}
                        </p>
                    </li>
                    <li>
                        <p>
                            <Trans
                                i18nKey="Make sure you have enough EggCoins to activate the course. You can <0>validate your balance and purchase more EggCoins here</0>."
                                t={t}
                                components={[
                                    <button className="link" onClick={() => chatWithSales()} />,
                                ]}
                            />
                        </p>
                    </li>
                </ul>

                <br />
                <br />
                <div className="buttons">
                    <button className="outlined" children={t(`Back`)} onClick={modalClose} />
                    <button
                        className="primary"
                        children={t(`Register`)}
                        onClick={() => {
                            onRegister?.()
                            modalClose()
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}
