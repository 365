import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Firebase, auth } from '../../../infrastructure/firebase'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useToken } from '../../../hooks/useToken'
import { CongratsStyle } from '../../marketplace/components/CongratsView'

export const EmailValidation = () => {
    const queyClient = useQueryClient()
    const { t } = useTranslation('signUp')
    const { _id } = useToken()
    const navigate = useNavigate()
    const [error, setError] = useState<string>()

    const { refetch, isFetching, isFetched, isSuccess } = useQuery({
        queryKey: ['Email verification'],
        queryFn: async () => {
            try {
                auth.currentUser &&
                    (await Firebase.sendEmailVerification(auth.currentUser).then(() => ({
                        ok: true,
                    })))
                setError(undefined)
            } catch (error: any) {
                error?.code === 'auth/too-many-requests'
                    ? setError(t('Too many attempts, try again later.'))
                    : setError(t('An error has occurred, try again later.'))
            }
        },
        enabled: false,
        retry: false,
        staleTime: 0,
    })

    const next = () =>
        queyClient
            .invalidateQueries({ queryKey: ['User', _id], exact: false })
            .then(() => navigate('/'))

    return (
        <CongratsStyle>
            <div>
                <header>
                    <FontAwesomeIcon
                        icon={['fat', 'circle-check']}
                        className="container-icon ppal-icon"
                        style={{ height: 80, color: '#00A3FF' }}
                    />
                </header>
                <main>
                    <h1>{t(`We're almost there!`)}</h1>
                    <p>
                        {t(
                            'To activate your account, we need you to confirm your email. Check your inbox and follow the instructions. Be sure to check your spam or junk mail.'
                        )}
                    </p>
                    <div className="buttons column">
                        <button className="text" onClick={() => refetch()} disabled={isFetching}>
                            {isFetching
                                ? t('Sending email, please wait...')
                                : isFetched
                                ? t('Re send email')
                                : t('Send email')}

                            <FontAwesomeIcon
                                icon={['fal', isFetching ? 'clock' : 'mailbox']}
                                style={{ color: '#00A3FF' }}
                                spin={isFetching}
                            />
                        </button>

                        {isSuccess && <h2>{t('Email sended!')}</h2>}

                        {error && (
                            <>
                                <br />
                                <div className="error">{error}</div>
                            </>
                        )}
                        <br />

                        <button
                            className="primary"
                            children={t('Continue')}
                            onClick={next}
                            style={{ padding: '7px 40px' }}
                            disabled={isFetching}
                        />
                    </div>
                </main>
            </div>
        </CongratsStyle>
    )
}
