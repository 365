import { useQuery } from '@tanstack/react-query'
import { getCountries as queryFn } from '../../pages/register/api-register'

export const useCountries = () => {
    const { data: countries, ...rest } = useQuery({
        queryKey: ['Countries'],
        queryFn,
    })
    return { countries, ...rest }
}
