import { useQuery } from '@tanstack/react-query'
import { getMyTeamData as queryFn } from '../infrastructure/api-widget'
import { useToken } from './useToken'

export const useMyTeam = (props?: { enabled?: boolean }) => {
    const { _profileId } = useToken()

    const { data: myTeam, ...rest } = useQuery({
        queryKey: ['User', 'My Team', _profileId],
        queryFn,
        staleTime: 1000 * 60 * 5, // 5 minutes
        ...props,
    })

    return { myTeam, ...rest }
}
