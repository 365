import React from 'react'

export const Separator = () => (
    <div style={{ display: 'flex', position: 'relative', padding: '30px 0' }}>
        <span
            style={{ backgroundColor: '#DEDEDE', height: '1px', flexGrow: '1', marginTop: '10px' }}
        />
        <span
            style={{
                padding: '0px 10px',
                fontWeight: '600',
            }}
        >
            O
        </span>
        <span
            style={{ backgroundColor: '#DEDEDE', height: '1px', flexGrow: '1', marginTop: '10px' }}
        />
    </div>
)
