// Doc: https://www.npmjs.com/package/react-google-recaptcha-v3

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const { REACT_APP_RECAPTCHA_KEY } = process.env

export const reCaptchaOptions: Omit<
    Parameters<typeof GoogleReCaptchaProvider>[number],
    'children'
> = {
    reCaptchaKey: `${REACT_APP_RECAPTCHA_KEY}`,
    scriptProps: {
        appendTo: 'body',
    },
    container: {
        parameters: {
            badge: 'bottomright',
        },
    },
}
