import React from 'react'
import { useOrganization } from '../hooks/useOrganization'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const eggLogo = require('../assets/egg_no_shadow.svg').default

export const LogoOrganization = ({ size }: { size?: 'small' }) => {
    const { branding } = useOrganization()

    return branding?.logo ? (
        <Logo className={`logo rounded ${size ?? ''}`}>
            <img src={branding?.logo} alt="Logo" className="rounded" />
        </Logo>
    ) : (
        <DefaultIcon className="column-center">
            <img src={eggLogo} alt="EGG" />
        </DefaultIcon>
    )
}

const Logo = styled.div`
    width: 86px;
    height: 86px;
    margin: 27px;
    padding: 4px;
    flex-shrink: 0;
    color: #a8b8c0;
    background: white;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    &.rounded,
    .rounded {
        border-radius: 8px;
    }
    &.small {
        width: 40px;
        max-width: 40px;
        height: 40px;
        margin: 0;
        box-shadow: none;
        > svg {
            width: 50%;
            height: 50%;
        }
    }
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &:not(.small) > svg {
        width: 30%;
        height: 100%;
    }
`

const DefaultIcon = styled.div`
    width: 40px;
    max-width: 40px;
    height: 40px;
    & > img {
        width: 22px;
        height: auto;
        object-fit: cover;
    }
`
