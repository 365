import { ReactNode } from 'react'
import styled from 'styled-components'

export const Title = ({
    title,
    subTitle = '',
    children,
    size = 'normal',
}: {
    title: string
    subTitle?: ReactNode
    children?: ReactNode
    size?: TitleSize
}) => (
    <StyledTitle size={size} className="title">
        <div>
            <h1>{title}</h1>
            <div style={{ fontSize: 16, opacity: 0.4 }}>{subTitle}</div>
        </div>
        <div className="right">{children}</div>
    </StyledTitle>
)

const StyledTitle = styled.div<{ size: TitleSize }>`
    display: flex;
    justify-content: space-between;
    .title {
        flex-grow: 2;
    }
    h1 {
        font-size: ${({ size }) => (size === 'large' ? 32 : 24)}px;
        font-weight: 700;
        letter-spacing: -0.64px;
        margin: 0;
        margin-top: 10px;
    }
    .right {
        font-size: 1.3rem;
        display: flex;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        .right {
            text-align: right;
        }
        h1 {
            font-size: ${({ size }) => (size === 'large' ? 24 : 20)}px;
            margin-top: 0;
        }
    }
`
type TitleSize = 'normal' | 'large'
