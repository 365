import { UserCoursesDTO } from '../interfaces/api-activation'
import DayJS from './DayJS'

export const extendCourseData = (course: UserCoursesDTO) => ({
    ...course,
    nextClass: course.nextClass && {
        ...course.nextClass,
        isInRange: DayJS().isBetween(DayJS(course.nextClass.open), DayJS(course.nextClass.end)),
    },
})
