import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FeatBlock } from '../../components/Content/FeatBlock'
import { StaticInfoBlock } from '../../components/Content/StaticInfoBlock'
import ModalPageLayout from '../../layouts/ModalPageLayout'
import { useActiveCourse } from '../course/hooks/useActiveCourse'
import { useCourseInfo } from '../course/hooks/useCourseInfo'

export const CourseInfo = () => {
    const { activeCourse } = useActiveCourse()
    const { info } = useCourseInfo(activeCourse?._courseId)
    return (
        <section>
            <h1>Información del curso</h1>
            <h2>Los detalles importantes que necesitas tener siempre a mano.</h2>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="icon-container">
                        <FontAwesomeIcon className="icon" icon={['fal', 'circle-info']} />
                    </div>
                    <div className="info-container">
                        <h4>Formato del curso</h4>
                        <p>
                            Encuentros en vivo donde te encontrarás con actividades 100% prácticas y
                            desafiantes. Contarás con el apoyo de tu equipo para avanzar en cada
                            momento.
                        </p>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="icon-container">
                        <FontAwesomeIcon className="icon" icon={['fal', 'clock']} />
                    </div>
                    <div className="info-container">
                        <h4>Días y horarios de cursado</h4>
                        <p>
                            {info?.schedule ? `${info.schedule} ` : 'Consulta tus horarios '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.timeanddate.com/time/difference/argentina/buenos-aires"
                            >
                                {` ${info?.timeZone ? info.timeZone : 'Argentina (UTC/GMT-3hs)'}`}
                            </a>
                        </p>
                    </div>
                </div>
                <FeatBlock>
                    <p>
                        Si estás fuera de Argentina y quieres saber a qué hora local comienzan tus
                        encuentros en vivo,{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.timeanddate.com/time/difference/argentina/buenos-aires"
                        >
                            haz click aquí.
                        </a>
                    </p>
                    <p>
                        Importante: Nos regimos por los feriados argentinos. Los mismos serán
                        informados con anterioridad por el Coach.
                    </p>
                </FeatBlock>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="icon-container">
                        <FontAwesomeIcon className="icon" icon={['fal', 'play-circle']} />
                    </div>
                    <div className="info-container">
                        <h4>Materiales de estudio</h4>
                        <p>
                            Guías de aprendizaje con elementos teóricos y ejercicios prácticos +
                            videos que te ayudarán a resolverlos.
                        </p>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="icon-container">
                        <FontAwesomeIcon className="icon" icon={['fal', 'circle-check']} />
                    </div>
                    <div className="info-container">
                        <h4>Aprobación del curso</h4>
                        <p>Deberás rendir un examen integrador final para aprobar tu curso.</p>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="icon-container">
                        <FontAwesomeIcon className="icon" icon={['fal', 'tree-palm']} />
                    </div>
                    <div className="info-container">
                        <h4>Periodo vacacional</h4>
                        {/* <p>{getVacationalPeriod()}</p> */}
                        <p>{info?.holidays ? info.holidays : 'Sin periodo vacacional.'}</p>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="icon-container">
                        <FontAwesomeIcon className="icon" icon={['fal', 'street-view']} />
                    </div>
                    <div className="info-container">
                        <h4>Asistencia y participación</h4>
                        <p>
                            ¡Tu presencia y participación activa en el equipo es importante! Deberás
                            asistir, como mínimo, al 80% de los encuentros en vivo.
                        </p>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="icon-container">
                        <FontAwesomeIcon className="icon" icon={['fal', 'video']} />
                    </div>
                    <div className="info-container">
                        <h4>Cámaras encendidas</h4>
                        <p>
                            Para aprender en equipo es necesario que todos sus integrantes estén
                            conectados. Para eso, tu cámara debe estar encendida durante todo el
                            encuentro. Esto ayudará a que las interacciones del equipo sean de mayor
                            calidad y que las personas allí presentes tengan una mejor experiencia
                            de aprendizaje.
                        </p>
                    </div>
                </div>
            </StaticInfoBlock>
            <StaticInfoBlock>
                <div className="block-content">
                    <div className="icon-container">
                        <FontAwesomeIcon className="icon" icon={['fal', 'screwdriver-wrench']} />
                    </div>
                    <div className="info-container">
                        <h4>Soporte técnico</h4>
                        <p>
                            {info?.supportPolicy
                                ? info.supportPolicy
                                : 'Ante consultas técnicas y soporte sobre Aula Virtual, ¡Nuestro bot tiene la solución!'}
                        </p>
                    </div>
                </div>
            </StaticInfoBlock>
        </section>
    )
}

const InfoPage = () => {
    return (
        <ModalPageLayout>
            <CourseInfo />
        </ModalPageLayout>
    )
}

export default InfoPage
