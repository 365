import { useState, useEffect } from 'react'

export const useResponsive = () => {
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        })
    }, [])

    const isMobile = width <= 768
    const isDesktop = !isMobile

    return {
        height,
        width,
        isMobile,
        isDesktop,
    }
}
