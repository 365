import { getUserContentDay } from '../../../infrastructure/api-widget'
import { useQuery } from '@tanstack/react-query'

export const useContentDay = (courseId?: string) => {
    const queryKey = ['ContentDay', courseId]

    const { data: contentDay, ...rest } = useQuery({
        queryKey,
        queryFn: () => getUserContentDay(`${courseId}`),
        enabled: !!courseId,
    })

    return { contentDay, ...rest }
}
