import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes/defaultTheme'
import { Icon } from '../../../Icon'
import { Dropdown, DropdownItem } from './NavBar.styles'
import useNavBarItems, { ItemMenuProps } from './useNavBarItems'
import { Trans, useTranslation } from 'react-i18next'
import { useUser } from '../../../../hooks/queries/useUser'
import { LogoOrganization } from '../../../LogoOrganization'

const eggLogo = require('../../../../assets/egg_no_shadow.svg').default

export const NavBarMobile = () => {
    const navigate = useNavigate()
    const { topMenuItems, bottomMenuItems, states } = useNavBarItems()
    const { activeItem, visibleMenu, setVisibleMenu, setVisibleIndexContent, visibleIndexContent } =
        states
    const { t } = useTranslation('home')
    const { name } = useUser()
    return (
        <Header className="only-mobile">
            {!visibleIndexContent && (
                <>
                    <div className="header-logo">
                        <button
                            className="icon outlined"
                            onClick={() => navigate('/home')}
                            style={{ height: '36px', width: '25px' }}
                        >
                            <LogoOrganization size="small" />
                        </button>
                        <span className="user-name">
                            <Trans
                                i18nKey="Hi <0>{{ name }}</0>!"
                                t={t}
                                values={{ name }}
                                components={[<p children={name} />]}
                            />
                        </span>
                    </div>
                    <nav className="header-icons">
                        <MenuButton
                            className="icon outlined toggle"
                            onClick={() => setVisibleMenu(!visibleMenu)}
                            open={visibleMenu}
                        >
                            <Icon
                                icon={visibleMenu ? ['far', 'times'] : ['far', 'bars']}
                                size="xl"
                            />
                        </MenuButton>
                    </nav>
                </>
            )}
            <Menu open={visibleMenu} className="menu-animation">
                <div>
                    <TopMenuRows>
                        {topMenuItems?.map(item => (
                            <MenuItem item={item} states={states} key={item.label} />
                        ))}
                    </TopMenuRows>
                </div>

                <BottomMenuRows>
                    {bottomMenuItems?.map(item => (
                        <MenuItem item={item} states={states} key={item.label} />
                    ))}
                </BottomMenuRows>
                {activeItem?.children && (
                    <Dropdown open={visibleIndexContent}>
                        <div className="buttons">
                            <MenuButtonContent
                                className="icon toggle"
                                onClick={() => {
                                    setVisibleIndexContent(false)
                                }}
                                open={visibleIndexContent}
                            >
                                <Icon
                                    icon={visibleIndexContent ? ['fal', 'times'] : ['fal', 'bars']}
                                    size="2xl"
                                />
                            </MenuButtonContent>
                        </div>
                        <DropdownItem>{activeItem?.children}</DropdownItem>
                    </Dropdown>
                )}
            </Menu>
        </Header>
    )
}

const MenuItem = ({ item, states }: { item: ItemMenuProps; states: ItemStates }) => {
    const { svgIcon, icon, activeIcon, activeSvgIcon, logo, label, isActive, hidden } = item

    const selectIconOrImage = (svgIconItem: ReactNode, iconItem?: IconProp) =>
        svgIconItem ?? (iconItem ? <Icon icon={iconItem} /> : logo)

    const iconToUse = selectIconOrImage(
        isActive ? activeSvgIcon : svgIcon,
        isActive ? activeIcon : icon
    )

    if (hidden) return null
    return (
        <MenuRow
            onClick={() => {
                handleMenuItemOnClick(item, states)
            }}
            active={isActive}
        >
            {iconToUse}
            <p className="small">{label}</p>
        </MenuRow>
    )
}

export const handleMenuItemOnClick = (item: ItemMenuProps, states: ItemStates) => {
    const { children, onClick, neverActiveMobile } = item
    const {
        setActiveItem,
        visibleMenu,
        setVisibleMenu,
        visibleIndexContent,
        setVisibleIndexContent,
    } = states
    if (children) {
        setActiveItem({ ...item, label: '' })
        setVisibleIndexContent(!visibleIndexContent)
    } else {
        !neverActiveMobile && setActiveItem(item)
        setVisibleMenu(!visibleMenu)
    }
    onClick?.()
}

type ItemStates = {
    activeItem: ItemMenuProps | undefined
    setActiveItem: Dispatch<SetStateAction<ItemMenuProps | undefined>>
    visibleMenu: boolean
    setVisibleMenu: Dispatch<any>
    visibleIndexContent: boolean
    setVisibleIndexContent: Dispatch<any>
}

const Header = styled.header`
    width: 100vw;
    height: ${defaultTheme.mobileHeight.header};
    padding: 0 40px 0 32px;
    display: grid;
    grid-template-columns: auto max-content;
    grid-gap: 1.3em;
    position: fixed;
    z-index: 3;
    right: 0em;
    top: 0em;
    border-bottom: 1px solid ${defaultTheme.color.lightGray};

    & > .header-logo {
        display: grid;
        grid-template-columns: max-content auto;
        grid-gap: 2rem;
        -webkit-box-align: center;
        align-items: center;
        & > button.menuIcon {
            padding: 0;
            min-width: unset;
            width: 25px;
            height: 36px;
        }
        & > button.icon:hover {
            padding: 0;
            min-width: unset;
        }
        & > .user-name {
            display: flex;
            align-items: center;
            gap: 0.2em;
            font-size: 1.2rem;
            font-weight: 600;
            & > p {
                font-size: 1.2rem;
            }
        }
    }

    & > .header-icons {
        display: flex;
        gap: 0.786rem;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: end;
        justify-content: flex-end;
    }
`
const MenuButton = styled.button<{ open?: boolean }>`
    display: block;
    z-index: 1000;
    padding: 1em;
    text-align: ${({ open }) => (open ? 'end' : 'start')};
`

const MenuButtonContent = styled(MenuButton)`
    text-align: end;
`

const Menu = styled.div<{ open: boolean }>`
    display: ${({ open }) => (open ? 'grid' : 'none')};
    position: fixed;
    inset: 0;
    background-color: white;
    padding: 5em 3em;
    grid-template-columns: 1fr;
    grid-template-rows: min-content max-content;
    justify-content: end;
    align-content: space-between;
    gap: 1.5rem;
    overflow-x: hidden;
    transition: all 0.3s ease-in-out;

    opacity: ${({ open }) => (open ? '1' : '0')};
    .menu-animation,
    > * {
        animation: ${({ open }) => (open ? 'fadeIn 0.3s ease-in-out' : 'fadeOut 0.3s ease-in-out')};
        @keyframes fadeIn {
            0% {
                display: none;
                opacity: 0;
            }
            1% {
                display: block;
                opacity: 0;
            }
            100% {
                display: block;
                opacity: 1;
            }
        }

        @keyframes fadeOut {
            0% {
                display: block;
                opacity: 1;
            }
            99% {
                display: block;
                opacity: 0;
            }
            100% {
                display: none;
                opacity: 0;
            }
        }
    }
`

const MenuRows = styled.div`
    display: grid;
    justify-items: center;
    gap: 10px;
    @media screen and (max-width: 768px) {
        justify-items: center;
    }
`

export const TopMenuRows = styled(MenuRows)`
    padding-bottom: 2rem;
    @media screen and (max-width: 768px) {
        padding-bottom: 0;
    }
`
export const CourseMenuRows = styled(MenuRows)`
    @media screen and (max-width: 768px) {
        gap: 0;
    }
`
export const BottomMenuRows = styled(MenuRows)`
    @media screen and (max-width: 768px) {
        gap: 0;
    }
`

export const MenuRow = styled.div<{ active: boolean; hover?: boolean }>`
    cursor: pointer;
    color: rgb(0, 55, 80);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        padding: 0.6rem;
        font-size: 22px;
    }
    &:hover {
        color: ${({ hover }) => (hover ? 'inherit' : defaultTheme.color.electricBlue)};
        background-color: ${({ hover }) => (hover ? defaultTheme.color.lightSilver : 'none')};
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        display: grid;
        grid-template-columns: 15% 85%;
        align-items: center;
        justify-content: center;
        svg,
        img,
        div {
            justify-self: center;
        }
    }
`
