const interceptorsHelper = {
    addInterceptors: axiosInstance => {
        // Add a response interceptor
        axiosInstance.interceptors.request.use(
            config => {
                try {
                    // The storage should be stringified
                    const token = JSON.parse(window.localStorage.getItem('lxp-token'))
                    if (token) {
                        config.headers.Authorization = token ? `${token}` : ''
                    }
                } catch (error) {
                    // Perhaps the token is not stringified
                    const token = window.localStorage.getItem('lxp-token')
                    if (token) {
                        config.headers.Authorization = token ? `${token}` : ''
                        console.info('Old token found, using it as a fallback...')
                    }
                    console.info('No token found, login out')
                }
                // const lng = i18n.language
                // lng && (config.headers['accept-language'] = `${lng}_${lng.toUpperCase()}`)
                return config
            },
            error => {
                return Promise.reject(error)
            }
        )

        // Add a response interceptor
        axiosInstance.interceptors.response.use(
            response => {
                // console.log('response', response)
                //@TODO handle when data is undefined
                const formattedResponse = {
                    status: response?.status,
                    data: response.data.data || response.data,
                    message: response.data.message,
                }
                return formattedResponse
            },
            error => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.data?.message === 'El perfil del usuario no existe'
                ) {
                    console.info('Unauthorized, login out')
                    window.localStorage.clear()
                    window.sessionStorage.clear()
                    window.location.replace('/')
                }
                const formattedErrorResponse = {
                    code: error.response.data.code || '0',
                    message: error.response.data.message || 'No error message (check interceptor)',
                }
                return Promise.reject(formattedErrorResponse)
            }
        )
    },
}

export default interceptorsHelper
