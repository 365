import { Navigate } from 'react-router-dom'
import { useUser } from '../hooks/queries/useUser'

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const { isLogged, isFetching } = useUser()
    if (!isFetching && !isLogged) return <Navigate to="/login" replace />
    return children
}

export default PrivateRoute
