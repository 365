import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export const BackToHomeButton = () => {
    const { t } = useTranslation('enroll')
    const navigate = useNavigate()
    return (
        <StyledHeaderActivation>
            <button className="icon" onClick={() => navigate('/home')} title={t('Close')}>
                <FontAwesomeIcon icon={['fal', 'chevron-left']} size="xl" />
            </button>
        </StyledHeaderActivation>
    )
}
const StyledHeaderActivation = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    min-height: 50px;
    button.icon > svg {
        margin-right: 0;
    }
`
