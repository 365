export const initSentry = () => {
    const dsn = process.env.REACT_APP_SENTRY_DSN
    if (!dsn || dsn === 'disabled')
        return console.warn('Sentry is disabled because REACT_APP_SENTRY_DSN is not set')

    import('@sentry/react').then(Sentry =>
        Sentry.init({
            dsn,
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            tracesSampleRate: 1.0,
            tracePropagationTargets: ['localhost', /^https:\/\/egg\.live/],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })
    )
}
