import { useMutation, useQueryClient } from '@tanstack/react-query'
import { API_AXIOS_ERROR, DefaultUserDTO } from '../../interfaces/api-activation'
import { updateUserImage as mutationFn } from '../../pages/register/api-register'
import { useToken } from '../useToken'

export const useUpdateUserImage = () => {
    const { _id } = useToken()
    const queryClient = useQueryClient()

    const queryKey = ['User', _id]
    const {
        mutate: uploadImage,
        mutateAsync: uploadImageAsync,
        isPending: isImageLoading,
        ...rest
    } = useMutation({
        mutationFn,
        onSettled: () => queryClient.invalidateQueries({ queryKey }),
    })

    return { uploadImage, uploadImageAsync, isImageLoading, ...rest }
}
