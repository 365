import { BarStyled } from './BarStyled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Title } from '../Type/Title'
import { useNavigate } from 'react-router-dom'

export const TitleBar = ({ pageTitle }: { pageTitle: string }) => {
    const navigate = useNavigate()
    return (
        <BarStyled style={{ margin: '3em auto' }}>
            <Title style={{ fontSize: '30px', fontWeight: '600' }}>{pageTitle}</Title>
            <button className="icon" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={['fas', 'times']} size="xl" style={{ marginRight: '0' }} />
            </button>
        </BarStyled>
    )
}
