import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLanguages } from '../../hooks/useLanguages'
import { useContentStep } from '../../pages/content/hooks/useContentStep'
import { useContentfulRenderOptions } from '../../hooks/useContentfulRenderOptions'

export const IntContent = ({ intl, hideTitle = false, titleStyles }: IntContentProps) => {
    const { t } = useTranslation('content')
    const { userLang } = useLanguages()
    const lang = userLang.resolvedLanguage ?? 'en'

    const stepId = intl[lang as keyof typeof intl] ?? intl.en
    const { step, isLoading } = useContentStep(stepId)
    const { options } = useContentfulRenderOptions('', stepId ?? '', '', '', '', '')

    if (isLoading) return <p>{t('Retrieving the content...')}</p>
    if (!stepId || !step) return <p>{t('Content not found!')}</p>
    else
        return (
            <>
                {!hideTitle && <h1 style={titleStyles}>{step.title}</h1>}
                {documentToReactComponents(step.description, options)}
            </>
        )
}

type IntContentProps = {
    intl: {
        en: string
        ['es']?: string
    }
    hideTitle?: boolean
    titleStyles?: React.CSSProperties
}
