import { ResetWithEmail } from './ResetWithEmail'
import { ResetWithToken } from './ResetWithToken'
import { isEmailValid } from '../../../../helpers/validators'
import { useParams } from 'react-router-dom'

export const PasswordReset = () => {
    const { emailOrToken } = useParams<{ emailOrToken: string }>()

    if (!emailOrToken) return null

    return isEmailValid(emailOrToken) ? (
        <ResetWithEmail email={emailOrToken} />
    ) : (
        <ResetWithToken token={emailOrToken} />
    )
}
