import LoadingPage from './static/LoadingPage'
import { getUrlParameters, updateSessionParams } from '../helpers/urls'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../hooks/queries/useUser'
import { useToken } from '../hooks/useToken'

export const Redirect = () => {
    const navigate = useNavigate()
    const { _id } = useToken()
    const { goTo, isLoading } = useUser()
    const { redirect, ...params } = getUrlParameters()

    useEffect(() => {
        if (!_id) return navigate('/login')

        if (typeof redirect === 'string' && !!redirect) {
            updateSessionParams({ ...params, redirect: undefined })
            navigate(redirect)
        } else if (goTo) navigate(goTo)
    }, [_id, redirect, goTo, navigate, params])

    return isLoading ? <LoadingPage /> : null
}
