import { useQuery } from '@tanstack/react-query'
import { getCourseInfo } from '../../../infrastructure/api-widget'

export const useCourseInfo = (courseId?: string) => {
    const queryKey = ['Course', 'Info', courseId]
    const queryFn = () => getCourseInfo(`${courseId}`)

    const { data: info, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: !!courseId,
    })
    return { info, ...rest }
}
