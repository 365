import { useNavigate, useParams } from 'react-router-dom'
import LoadingPage from '../../static/LoadingPage'
import { setNewAccessToken } from '../../../infrastructure/apis'
import { useEffect } from 'react'

export const AuthWithAccessToken = () => {
    let { token, redirect = '/' } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (!token) return navigate('/login')

        setNewAccessToken(token)
        navigate(redirect)
    }, [token, redirect, navigate])

    return <LoadingPage />
}
