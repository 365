import { getTimeline } from '../infrastructure/api-egg'
import { useQuery } from '@tanstack/react-query'
import { useToken } from './useToken'

export const useTimeline = (courseId?: string) => {
    const { _classId } = useToken()
    const id = courseId ?? _classId
    const key = ['Team', 'Timeline', id]

    const { data, ...rest } = useQuery({
        queryKey: key,
        queryFn: () => getTimeline(id),
        enabled: !!id,
    })

    return {
        ...data,
        ...rest,
    }
}
