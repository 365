import NavBarDesktop from '../components/DesignSystem/Molecules/NavBar/NavBarDesktop';
import styled from 'styled-components';
import { defaultTheme } from '../styles/themes/defaultTheme';
import { NavBarMobile } from '../components/DesignSystem/Molecules/NavBar/NavBarMobile';
import { ReactNode } from 'react';

export const ResponsiveLayout = ({
    isWrap = false,
    onlyDesktop = false,
    allowScroll = true,
    header,
    content,
    aside,
    footer,
}: {
    isWrap?: boolean
    onlyDesktop?: boolean
    allowScroll?: boolean
    header: ReactNode
    content: ReactNode
    aside: ReactNode
    footer: ReactNode
}) => {
    const hasAside = aside ? 'aside' : 'no-aside'
    const showAsideMobile = aside && onlyDesktop ? 'hidden-aside-mobile' : ''
    const scrollBehavior = allowScroll ? 'scroll-section' : ''

    return (
        <Layout className="redesign">
            <Section className={`${hasAside} ${showAsideMobile} ${scrollBehavior}`}>
                <NavBarDesktop />
                <NavBarMobile />
                <div className="col-2">
                    <Main className={`${!isWrap ? 'max-w-1310' : 'max-w-100vw'}`}>
                        {header && <Header>{header}</Header>}
                        <Content hasHeader={!!header}>{content}</Content>
                    </Main>
                    {footer && <Footer className="only-desktop max-w-1310">{footer}</Footer>}
                </div>
                {aside && (
                    <Aside className={` ${onlyDesktop ? 'only-desktop' : ''}`}>{aside}</Aside>
                )}
            </Section>
        </Layout>
    )
}

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    background-color: ${defaultTheme.color.offWhite};

    @media (max-width: 768px) {
        background-color: ${defaultTheme.color.white};
    }
`

const Section = styled.section`
    &.scroll-section {
        overflow: auto;
        ::-webkit-scrollbar {
            width: 0px;
        }
        scroll-behavior: smooth;
    }
    display: grid;
    height: 100%;
    grid-template-columns: ${defaultTheme.width.navBar} 1fr auto;
    grid-template-areas: 'nav main aside';

    &.no-aside {
        grid-template-columns: ${defaultTheme.width.navBar} 1fr;
        grid-template-areas: 'nav main';
    }

    & > nav {
        grid-area: nav;
    }
    & .col-2 {
        grid-area: main;
        justify-self: center;

        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;

        overflow: auto;
        ::-webkit-scrollbar {
            width: 0px;
        }
        scroll-behavior: smooth;
    }
    & > aside {
        grid-area: aside;
        min-height: 100vh;
        margin: 0 auto;
    }
    & > .col-2 > footer {
        width: 100%;
        padding: 2em;
    }

    @media (max-width: 768px) {
        margin: 0;
        padding: 0;
        height: 100dvh;
        overflow: hidden;
        grid-template-areas: 'nav' 'main' 'aside';
        grid-template-columns: 1fr;
        grid-template-rows: ${defaultTheme.mobileHeight.header} auto ${defaultTheme.mobileHeight
                .footer};

        &.no-aside,
        &.hidden-aside-mobile {
            overflow: hidden;
            grid-template-areas: 'nav' 'main';
            grid-template-columns: 1fr;
            grid-template-rows: ${defaultTheme.mobileHeight.header} auto;
        }

        & > nav {
            width: 100vw;
            position: sticky;
            left: 0;
            top: 0;
        }

        & > .col-2 > main {
            width: 100vw;
            box-sizing: border-box;
            background-color: ${defaultTheme.color.offWhite};
        }

        & > aside {
            min-height: unset;
        }
    }
    @media screen and (max-width: 1439px) {
        & > .col-2 > main {
            padding: 0 1rem;
        }
    }
`

const Main = styled.main`
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        overflow: auto;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            width: 0px;
        }
    }
`

const Header = styled.header`
    display: flex;
    text-align: center;
    @media screen and (max-width: 768px) {
        display: none;
    }
`

const Content = styled.main<{ hasHeader: boolean }>`
    height: 86%;
    margin-top: ${({ hasHeader }) => (hasHeader ? '0' : '2em')};
    @media screen and (max-width: 768px) {
        height: 100dvh;
        & > img {
            max-width: 100%;
        }
        & > .loading-page {
            width: 100%;
            max-width: 100%;
            height: 100%;
            height: -webkit-fill-available;
            inset: ${defaultTheme.mobileHeight.header} 0 ${defaultTheme.mobileHeight.footer} 0 !important;
            background-color: ${defaultTheme.color.offWhite} !important;
        }
    }
`

const Aside = styled.aside`
    @media screen and (max-width: 768px) {
        width: 100%;
        height: ${defaultTheme.mobileHeight.footer};
        padding: 0;
    }
`

const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
`
