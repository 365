import { useQuery } from '@tanstack/react-query'
import { getProvinces } from '../../pages/register/api-register'

export const useProvinces = (idCountry?: number) => {
    const queryKey = ['Provinces', idCountry]
    const { data: provinces, ...rest } = useQuery({
        queryKey,
        queryFn: () => getProvinces(`${idCountry}`),
        enabled: !!idCountry,
    })
    return { provinces, ...rest }
}
