import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { API_AXIOS_ERROR } from '../../interfaces/api-activation'

export const Select = ({
    label,
    labelProps,
    error: propError,
    api_error,
    ...rest
}: SelectProps) => {
    const [error, setError] = useState<string | undefined>(propError)

    useEffect(() => {
        if (!api_error) return
        const inputError = (api_error as API_AXIOS_ERROR)?.response?.data?.errors?.find(
            ({ key, message }) => key === rest.name && message
        )?.message
        setError(inputError)
    }, [api_error, rest.name])

    useEffect(() => {
        setError(propError)
    }, [propError])

    return (
        <Styles>
            <label {...labelProps}>
                {label}
                <select name="name" id="name" {...rest} />
                {error && <div className="error">{error}</div>}
            </label>
        </Styles>
    )
}

export interface SelectProps
    extends React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    label?: ReactNode
    labelProps?: React.DetailedHTMLProps<
        React.LabelHTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
    >
    error?: string
    api_error?: unknown
}

const Styles = styled('div')`
    label {
        display: flex;
        flex-direction: column;
    }
    input:invalid {
        border: 2px solid ${defaultTheme.color.errors};
    }
    .error {
        color: ${defaultTheme.color.errors};
    }
`
