import { useTranslation } from 'react-i18next'
import { Icon } from '../Icon'
import Styles from './CallToCoachModal.module.css'
import { Modal } from './Modal'
import { useUIContext } from '../../context/UIContext'
import { useMutation } from '@tanstack/react-query'
import { teamCallToCoach as mutationFn } from '../../infrastructure/api-widget'

export const CallToCoachModal = ({ tableNumber }: { tableNumber: number }) => {
    const { modalClose, showToast } = useUIContext()
    const { t } = useTranslation('myTeam')

    const { mutate } = useMutation({
        mutationFn,
        onMutate: () => modalClose(),
        onSuccess: () => showToast(t('Help request sent.'), { type: 'success', duration: 5000 }),
    })

    return (
        <Modal>
            <section className={Styles.container}>
                <div className={Styles.icon}>
                    <Icon icon={['fas', 'user-headset']} />
                </div>
                <br />
                <h2>{t('Request external help')}</h2>
                <p>
                    {t(
                        'When requesting external help, you will call a qualified person in the matter to clarify doubts that the team has not been able to resolve.'
                    )}
                </p>
                <br />
                <div className="buttons column w-100">
                    <button className="primary big" onClick={() => mutate({ tableNumber })}>
                        {t('Request help')}
                    </button>
                    <button className="text big" onClick={modalClose}>
                        {t('Cancel')}
                    </button>
                </div>
            </section>
        </Modal>
    )
}
