import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Icon } from '../../components/Icon'

type Props = {
    metric?: '%' | 'vh'
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

const LoadingPage = ({ metric = 'vh', ...rest }: Props) => (
    <div
        className="container redesign"
        style={{
            position: 'absolute',
            width: `100vw`,
            inset: '0',
            backgroundColor: 'white',
            display: 'grid',
            placeItems: 'center',
            zIndex: 1,
        }}
    >
        <LoadingSpinner style={{ fontSize: '6em', transform: 'unset' }} />
    </div>
)

export default LoadingPage

export const LoadingSpinner = (props: Omit<FontAwesomeIconProps, 'icon'>) => (
    <Icon icon={['fal', 'bolt']} beat={true} {...props} />
)
