import styled from 'styled-components'

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
    /* Add if not using autoprefixer */
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.5em;
    height: 1.5em;
    border: 0.15em solid ${props => props.theme.color.gray};
    border-radius: 0.35em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-right: 12px;
    cursor: pointer;
    &:before {
        content: '';
        width: 0.85em;
        height: 0.85em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${props => props.theme.color.electricBlue};
        background-color: CanvasText;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    &:checked:before {
        transform: scale(1);
    }
    &:focus {
        /* outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em); */
    }
    &:disabled {
        --form-control-color: var(--form-control-disabled);
        color: var(--form-control-disabled);
        cursor: not-allowed;
    }
`
