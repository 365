import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BgForm from '../assets/referrer_desk_bg.jpg'
import DynamicCard from '../components/DashModules/DynamicCard'
import { TitleBar } from '../components/NavBar/TitleBar'
import { GACategory, SendGAEvent } from '../helpers/GAEvents'
import { GetProfileType } from '../interfaces/api'
import { CardType } from '../interfaces/user'
import { ResponsiveLayout } from '../layouts/ResponsiveLayout'
import { defaultTheme } from '../styles/themes/defaultTheme'

const Referrals = () => {
    const [link, setLink] = useState('')

    const inputRef = useRef<HTMLInputElement>(null)
    const [tooltipVisible, setTooltipVisible] = useState(false)

    const getReferralLink = async () => {
        try {
            const res = await axios.get<GetProfileType>(
                `${process.env.REACT_APP_WIDGET_API}/v1/profile`
            )
            if (res.data.profile.referral.link) {
                setLink(res.data.profile.referral.link)
            } else {
                setLink('Link no disponible')
            }
        } catch (error) {
            console.log('Referrer link unavailable', error)
            setLink('Link no disponible')
        }
    }

    useEffect(() => {
        getReferralLink()
    }, [])

    const copyToClipboard = () => {
        if (inputRef.current) {
            inputRef.current.select()
            navigator.clipboard
                .writeText(inputRef.current.value)
                .then(() => {
                    console.log('Text copied to clipboard')
                })
                .catch(error => {
                    console.error('Error copying text to clipboard:', error)
                })
        }

        setTooltipVisible(true)
        setTimeout(() => {
            setTooltipVisible(false)
        }, 2000) // Hide the tooltip after 2 seconds
        SendGAEvent(GACategory.REFERRER, 'Referrer link copied')
    }
    return (
        <ResponsiveLayout
            header={null}
            content={
                <ReferralsPage>
                    <TitleBar pageTitle="Referidos" />
                    <section className="heading">
                        <h2>Conéctate al programa de referidos ¡Y Gana!</h2>
                        <p className="lead">
                            Invita a tus amigos a Egg y estudia gratis cualquiera de nuestros
                            cursos. <strong>Copia y pega el siguiente enlace.</strong>
                        </p>
                    </section>
                    <section className="form">
                        <div className="formu">
                            <input ref={inputRef} disabled type="text" value={link} />
                            <button onClick={copyToClipboard}>
                                <FontAwesomeIcon icon={['fal', 'copy']} />
                            </button>
                        </div>
                        {tooltipVisible && <div className="copied-tip">Enlace copiado!</div>}
                    </section>
                    <section className="featured-copy">
                        <p>
                            Cada vez que uno de tus amigos llegue a su día 5 de cursado en
                            Programación Web desde Cero, <span>ambos ganan un mes gratis</span> para
                            continuar formándose en Egg.
                        </p>
                    </section>
                    <section className="referrals-grid">
                        {cardsContent.map(page => {
                            return (
                                <div className="grid-card">
                                    <DynamicCard
                                        icon={[page.iconPrefix, page.icon]}
                                        title={page.title}
                                        desc={page.desc}
                                        onActionClick={page.onClick}
                                    />
                                </div>
                            )
                        })}
                    </section>
                    <section className="close">
                        <h3>¡No hay límite!</h3>
                        <p>Puedes invitar a tantos amigos como quieras.</p>
                        <a
                            href={
                                'https://eggeducacion-com.s3.amazonaws.com/assets-lxp/POLITICA_DE_PROGRAMA_DE_REFERIDOS_DE_EGG_2.pdf'
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            Ver términos y condiciones.
                        </a>
                    </section>
                </ReferralsPage>
            }
            aside={null}
            footer={null}
        />
    )
}

export default Referrals

const ReferralsPage = styled.div`
    .heading {
        h2 {
            font-size: 22px;
            font-weight: 500;
        }
        p.lead {
            font-size: 16px;
            color: ${props => props.theme.color.primary};
        }
    }
    .form {
        margin: 45px 0 25px;
        border-radius: 20px;
        background: url(${BgForm});
        background-size: cover;
        padding: 60px 25px;
        div.formu {
            display: flex;
        }
        .copied-tip {
            color: white;
            margin-top: 10px;
        }
        input {
            flex: 1;
            border-radius: 10px;
            height: 55px;
            margin-right: 10px;
            border: none;
            background-color: rgba(255, 255, 255, 0.2);
            color: white;
            font-size: 18px;
            padding: 0 20px;
        }
        button {
            background: none;
            border: none;
            color: white;
            font-size: 32px;
            cursor: pointer;
            margin: 0 15px;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .featured-copy {
        margin: 60px 0;
        p {
            font-size: 40px;
            font-weight: 600;
            line-height: 3.5rem;
            text-align: center;
            color: ${defaultTheme.color.primary};
            span {
                font-size: 40px;
                color: ${defaultTheme.color.lightBlue};
            }
        }
    }
    .referrals-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 20px;
        .grid-card {
            /* width: 50%; */
        }
    }
    .close {
        margin: 100px 0;
        text-align: center;
        h3 {
            font-size: 28px;
            font-weight: 600;
            color: ${props => props.theme.color.primary};
            margin-bottom: 15px;
        }
        p {
            font-size: 20px;
            border-bottom: ${props => `1px solid ${props.theme.color.lightGray}`};
            padding-bottom: 30px;
            color: ${props => props.theme.color.primary};
        }
        a {
            display: inline-block;
            padding-top: 30px;
            font-size: 14px;
            color: ${props => props.theme.color.primary};
        }
    }
`

const cardsContent: CardType[] = [
    {
        iconPrefix: 'fal',
        icon: 'user-group',
        title: '01.',
        desc: 'Para obtener tus meses gratis, solo necesitas referir amigos a Programación Web desde Cero.',
        showCard: true,
    },
    {
        iconPrefix: 'fal',
        icon: 'calendar',
        title: '02.',
        desc: 'Una vez que tu referido se inscriba y asista al menos 5 clases, recibirás un mes gratis.',
        showCard: true,
    },
    {
        iconPrefix: 'fal',
        icon: 'bag-shopping',
        title: '03.',
        desc: 'Recuerda que, para canjear tus referidos por meses gratuitos, debes pagar la primera cuota de suscripción de los cursos Full Stack, Backend, Frontend y QA.',
        showCard: true,
    },
    {
        iconPrefix: 'fal',
        icon: 'cart-shopping',
        title: '04.',
        desc: 'Para que tu referido gane un mes gratis, debe culminar el curso PWDC y pagar la primera cuota de suscripción de alguno de los cursos de Egg.',
        showCard: true,
    },
]
