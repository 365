import { useQuery } from '@tanstack/react-query'
import { useUser } from './queries/useUser'
import { getOrganizationBranding } from '../infrastructure/api-activation'

export const useOrganization = () => {
    const { user } = useUser()
    const { organizationId, organizationName } = user ?? {}
    const { data, ...rest } = useQuery({
        queryKey: ['Organization', user?.organizationId ?? ''],
        queryFn: async () => {
            const branding = await getOrganizationBranding(organizationId!)
            const primaryColor = branding.actionButtonColor
            const accentColor = branding.accentColor
            const cardBackground = branding.cardBackground
            const cardFontColor = branding.cardFontColor
            document.documentElement.style.setProperty('--color-primary', primaryColor)
            document.documentElement.style.setProperty('--color-accent', accentColor)
            document.documentElement.style.setProperty('--card-background', cardBackground)
            document.documentElement.style.setProperty('--card-font-color', cardFontColor)
            return branding
        },
        enabled: !!user?.organizationId,
    })

    const branding = data && Object.values(data).every(value => !value) ? null : data

    return { orgId: organizationId, orgName: organizationName, branding, ...rest }
}
