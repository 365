import { useQuery } from '@tanstack/react-query'
import { getCoursesOfStudent } from '../../../infrastructure/api-ranking'
import { useToken } from '../../../hooks/useToken'

export const useCourses = () => {
    const queryKey = ['UserCourses']
    const { _profileId } = useToken()

    const { data: courses, ...rest } = useQuery({
        queryKey,
        queryFn: () => getCoursesOfStudent(),
        enabled: !!_profileId,
        retry: 2,
    })

    return {
        courses,
        ...rest,
    }
}
