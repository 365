// Documentation: https://www.npmjs.com/package/react-player

import Player, { type ReactPlayerProps } from 'react-player'
import { useUIContext } from '../context/UIContext'
import { useLanguages } from '../hooks/useLanguages'

export const Video = ({ SpanishUrl, EnglishUrl, ...rest }: VideoProps) => {
    const { userLang } = useLanguages()

    rest.url = userLang.language === 'en' ? EnglishUrl : SpanishUrl

    return <Player {...rest} />
}

export const VideoAutoClose = (props: VideoProps) => {
    const { modalClose } = useUIContext()
    const onEnded = () => setTimeout(modalClose, 1000 * 3)
    return <Video {...props} onEnded={onEnded} />
}

export type VideoProps = Omit<ReactPlayerProps, 'url'> & {
    SpanishUrl: string
    EnglishUrl: string
}
