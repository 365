import styled, { css } from 'styled-components'
import { defaultTheme } from '../../styles/themes/defaultTheme'
interface Props {
    colorTheme: 'light' | 'dark'
}

export const PrimaryButton = styled.button`
    font-family: 'Poppins', sans-serif;
    height: 40px;
    background: ${props => props.theme.color.primary} 0% 0% no-repeat padding-box;
    border-radius: ${defaultTheme.borderRadius};
    border: none;
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin: 18px 0;
    padding: 0px 22px;
    &:disabled {
        background: ${props => props.theme.color.lightGray} 0% 0% no-repeat padding-box;
        color: ${props => props.theme.color.gray};
    }
    &:hover:not(:disabled) {
        background-color: ${props => props.theme.color.blue};
    }
    cursor: pointer;
    .btn-icon {
        margin-right: 10px;
        font-size: 16px;
    }
`

export const PrimaryBtnInverted = styled(PrimaryButton)`
    background: white;
    color: ${props => props.theme.color.electricBlue};
    margin: 0;
    border: 1px solid ${props => props.theme.color.borders};
    &:hover:not(:disabled) {
        background: ${props => props.theme.color.lightGray};
        color: white;
    }
`

export const PrimaryBtnBlue = styled(PrimaryButton)`
    background: #00a3ff;
    color: white;
    margin: 0;
    &:hover:not(:disabled) {
        background: #0095ea;
        color: white;
    }
`

export const PrimaryBtnInvertedBlue = styled(PrimaryButton)`
    background: white;
    color: ${props => props.theme.color.primaryOpacity};
    margin: 0;
    &:hover:not(:disabled) {
        color: ${props => props.theme.color.lightBlue};
        background: none;
    }
    &:disabled {
        background: 0;
    }
`

export const PrimaryBtnOutline = styled(PrimaryButton)<Props>`
    border: 2px solid
        ${props => (props.colorTheme === 'light' ? 'white' : props.theme.color.primary)};
    background-color: transparent;
    color: ${props => (props.colorTheme === 'light' ? 'white' : props.theme.color.primary)};
    &:hover:not(:disabled) {
        background-color: ${props =>
            props.colorTheme === 'light'
                ? props.theme.color.lightBlue
                : props.theme.color.primaryOpacity};
    }

    ${({ colorTheme }) =>
        colorTheme !== 'light' &&
        css`
            &:hover {
                color: white;
            }
        `}
`
