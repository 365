import Axios from 'axios'
import DayJS from '../helpers/DayJS'
import { Course as CourseApp, GetContentInfo, GetUserResponse } from '../interfaces/api'
import { Document as RichTextDocument } from '@contentful/rich-text-types'
import { refreshInterceptor, setNewAccessToken } from './apis'
import {
    API_WIDGET_RESPONSE,
    GetCourseResponse,
    GetUserContent,
    PostCodeToEmail,
} from '../interfaces/api-widget'
const { REACT_APP_WIDGET_API } = process.env

if (!REACT_APP_WIDGET_API)
    console.error('REACT_APP_WIDGET_API to be declared on your system environments variables')

const baseURL = `${REACT_APP_WIDGET_API}`

export const ApiWidget = Axios.create({ baseURL })

ApiWidget.interceptors.response.use(
    ok => ok,
    error => refreshInterceptor(error, ApiWidget)
)

export const getActiveCourse = (courseId: string) =>
    ApiWidget.get<GetCourseResponse>(`/v1/lxp/active-course/${courseId}`).then(
        ({ data }) => data.data
    )

export const getCourses = () =>
    ApiWidget.get<API_WIDGET_RESPONSE<{ courses: CourseApp[] }>>(`/v1/lxp/courses`).then(
        ({ data }) => data.data.courses
    )

export const getCourseInfo = (courseId: string) =>
    ApiWidget.get<API_WIDGET_RESPONSE<GetContentInfo>>(`/v1/lxp/${courseId}/course-info`).then(
        ({ data }) => data.data
    )

export const getActiveContent = (courseId: string) =>
    ApiWidget.get<GetCourseResponse>(`/v1/lxp/active-course/${courseId}`).then(
        ({ data }) => data.data
    )

export const sendCodeToEmail = (email: string, captcha_token: string) =>
    ApiWidget.post<PostCodeToEmail>(`/v1/auth/passwordless-validation`, {
        email,
        captcha_token,
    }).then(({ data }) => data.data)

export const getCourseContent = (courseId: string) =>
    ApiWidget.get<GetUserContent>(`/v1/lxp/get-user-content/${courseId}`).then(
        ({ data }) => data.data
    )

export const loginFromAdmin = (code: string) =>
    ApiWidget.get<API_WIDGET_RESPONSE<GetUserResponse>>(
        `${process.env.REACT_APP_WIDGET_API}/v1/lxp/get-user/${code}`
    ).then(({ data }) => {
        setNewAccessToken(data.data.token)
        return data
    })

export const getUserContentDay = async (courseId: string) =>
    ApiWidget.get<API_WIDGET_RESPONSE<UserContentDTO>>(`/v1/lxp/get-user-content/${courseId}`).then(
        ({ data }) => data.data
    )

export const getStudyPlan = (courseId: string) =>
    ApiWidget.get<API_WIDGET_RESPONSE<StudyPlanDTO>>(
        `/v1/course-content/${courseId}/study-plan`
    ).then(({ data }) => data.data)

export const getContentStep = async (stepId: string) =>
    ApiWidget.get<API_WIDGET_RESPONSE<{ step: ContentStepDTO }>>(
        `/v1/course-content/step/${stepId}`
    ).then(({ data }) => data.data.step)

export const setUserPresentOld = async (courseId: string) => {
    const key = `present-${courseId}`
    try {
        const date = DayJS()
        sessionStorage.setItem(key, JSON.stringify({ date: date.toISOString(), courseId }))
        console.info(`User set present at ${date.format('HH:mm:ss')} for course ${courseId}`)
        return await ApiWidget.post<API_WIDGET_RESPONSE<PresentResponseDTO>>(
            `/v1/user/present`
        ).then(({ data }) => data.data)
    } catch (error) {
        sessionStorage.removeItem(key)
        return null
    }
}

export const givePulse = async (params: GivePulseParams) =>
    ApiWidget.post<API_WIDGET_RESPONSE<{ voteData: GivePulseDTO }>>(`/v1/vote`, params).then(
        ({ data }) => data.data.voteData
    )

export const getMyTeamData = async () =>
    ApiWidget.get<API_WIDGET_RESPONSE<MyTeamDataDTO>>(`/v1/user/info`).then(({ data }) => data.data)

export const teamCallToCoach = async ({ tableNumber }: { tableNumber: number }) =>
    ApiWidget.post<API_WIDGET_RESPONSE<{ message: string }>>(`/v1/help-request/activationTeam`, {
        tableNumber,
    }).then(({ data }) => data.data)

// Interfaces
export interface GivePulseParams {
    _algorithmId?: string
    _profileToId: string
}

interface GivePulseDTO {
    _profileFromId: string
    _profileToId: string
    _algorithmId: string
    type?: any
    sqsCreatedAt: number
}
export type MyTeamDataDTO = {
    me: {
        voteData: {
            _profileFromId: string
            _profileToId: string // este
            createdAt: string
        }
    }
    table: {
        tableNumber: number
        voteData: {
            hasVoted: boolean
        }
        isMentorTeam: boolean
        isExpertMentorTeam: boolean
        tableNumberToVote: number
        facilitator: string
        positions: {
            isMentor: boolean
            isExpertMentor: boolean
            isExpertMentorTeam: boolean
            available: boolean
            number: number
            _profileId: string
            _userId: {
                _id: string
                lastname: string
                name: string
                urlImage?: string
            }
            content: number
        }[]
        helpRequest?: any
    } | null
    team: {
        settings: {
            enabledActivationTeam: boolean
        }
    }
}

interface PresentResponseDTO {
    present: boolean
    status: string
    canSetPresent: boolean
}

export interface UserContentDTO {
    contentDist: {
        contentDescription: string | null
        contentDay: number | null
        module: string | null
        section: string | null
        firstModule: string | null
        firstSection: string | null
    }
    enableDiscord: boolean
    publicity: {
        title: string
        urlImage: string
        urlPage: string
    }[]
    enableReferralCode: boolean
}

export interface StudyPlanDTO {
    course: Course
    plan: Plan
}

export interface Course {
    name: string
}
export interface Plan {
    _id: string
    title: string
    type: string
    createdAt: string
    updatedAt: string
    modules: Module[]
}

export interface Module {
    _id: string
    title: string
    type: string
    sections: Section[]
}

export interface Section {
    _id: string
    title: string
    type: string
    steps: Step[]
}

export interface Step {
    _id: string
    title: string
    type: string
}
export interface ContentStepDTO {
    _id: string
    title: string
    type: string
    principalMedia?: any
    slug?: string
    description: RichTextDocument
    suggestedExercise?: RichTextDocument
    createdAt: string
    updatedAt: string
}
