import { useQuery } from '@tanstack/react-query'
import { getFrequencies } from '../api-marketplace'

export const useFrequency = (slug?: string) => {
    const queryKey = ['Course', slug, 'Frequency']
    const { data: frequencies, ...rest } = useQuery({
        queryKey,
        queryFn: () => getFrequencies(`${slug}`),
        enabled: !!slug,
    })

    const getFrequencyByCourseId = (courseId?: string) =>
        frequencies?.find(frequency => frequency.courses.some(course => course.id === courseId))

    const getCourseById = (courseId?: string) =>
        frequencies
            ?.flatMap(item => item.courses.find(course => course.id === courseId))
            .filter(Boolean)[0]

    return { frequencies, getFrequencyByCourseId, getCourseById, ...rest }
}
