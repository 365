import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Blur } from '../components/Blur/Blur'
import BtnSquare from '../components/Buttons/BtnSquare'
import { MeetButton } from '../components/CourseCard/MeetButton'
import { ContentCard, getStatus } from '../components/DashModules/ContentCard'
import { UIContext } from '../context/UIContext'
import { capitalizeAll } from '../helpers/formatters'
import { useUser } from '../hooks/queries/useUser'
import { useUserCourses } from '../hooks/queries/useUserCourses'
import { useContentfulRenderOptions } from '../hooks/useContentfulRenderOptions'
import { useToken } from '../hooks/useToken'
import { ContentStepDTO } from '../infrastructure/api-widget'
import ModalPageLayout from '../layouts/ModalPageLayout'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { useContentStep } from './content/hooks/useContentStep'
import { Section, useStudyPlan } from './course/hooks/useStudyPlan'
import LoadingPage from './static/LoadingPage'
import { TranslationWidget } from '../components/TranslationWidget'
import { useAppNavigate } from '../hooks/useAppNavigate'

const ContentBoard = () => <Content />

export default ContentBoard

export const Dash = styled.section`
    background-color: ${props => props.theme.color.offWhite};
    row-gap: 20px;
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
`
const Content: React.FC = () => {
    const { stepId } = useParams()
    const { navigate } = useAppNavigate()
    const topRef = useRef<HTMLDivElement | null>(null)
    const { showBlur } = useContext(UIContext)
    const { _profileId, _classId } = useToken()
    const { user } = useUser()
    const { currentCourse, currentCourseName } = useUserCourses()
    const { step, isLoading } = useContentStep(stepId)
    const { getPrevAndNextStepId, getModuleAndSection, studyPlan, todayContent } =
        useStudyPlan(_classId)
    const { moduleId, sectionId } = getModuleAndSection(stepId)
    const { options } = useContentfulRenderOptions(
        _profileId ?? '',
        stepId ?? '',
        moduleId ?? '',
        sectionId ?? '',
        _classId ?? '',
        user?.email ?? ''
    )
    const [section, setSection] = useState<Section | null>(null)

    const { plan } = studyPlan || {}
    const { modules = [] } = plan || {}
    const sections = modules.flatMap(module => module.sections)
    const todaySection = sections.find(section => section._id === todayContent?.section?._id)

    const stepNavigate = ({ event, currentStepId, moduleId, sectionId, type }: NavigationType) => {
        event.stopPropagation()
        const { prev, next } = getPrevAndNextStepId(moduleId, sectionId, currentStepId, studyPlan!)
        navigate(`/content/step/${type === 'prev' ? prev : next}`)
        topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    const getCurrentSection = () => {
        return sections.find(section => section._id === sectionId)
    }

    useEffect(() => {
        const section = moduleId && sectionId && getCurrentSection()
        section && setSection(section)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleId, sectionId])

    if (isLoading || !step || !section) return <LoadingPage metric="%" />

    const { description, suggestedExercise } = step

    const handleHeaderOnClick = () => navigate(`/dashboard/${_classId}?sectionId=${section._id}`)

    return (
        <ContentBoardStyled>
            <div className="content-board-header">
                <HeaderStyled>
                    <div className="left-part">
                        <BtnSquare
                            mode="light"
                            onClick={handleHeaderOnClick}
                            data-testid="back-btn"
                        >
                            <FontAwesomeIcon icon={['fas', 'list']} />
                        </BtnSquare>
                        <button
                            className="content-board-button underline-hover"
                            onClick={handleHeaderOnClick}
                        >
                            <h3 className="content-board-title truncate">
                                {capitalizeAll(currentCourseName)}
                            </h3>
                        </button>
                    </div>
                    <div style={{ display: 'flex', gap: 20 }}>
                        <TranslationWidget organizationId={user?.organizationId} />
                        {currentCourse && <MeetButton {...currentCourse} isInside={true} />}
                    </div>
                </HeaderStyled>
                <ContentCard
                    content={section!}
                    status={getStatus(section!, todaySection!)}
                    isTodaySection={todaySection?._id === section._id}
                    icon={['fas', 'circle-arrow-right']}
                    enableDuration={false}
                    navigationButtons={
                        <StepsNavigator stepContent={step} stepNavigate={stepNavigate} />
                    }
                    isInsideContent={true}
                />
            </div>
            <ModalPageLayout>
                {showBlur && <Blur zIndex={1300}></Blur>}
                <Title ref={topRef}>
                    <h1 className="content-title">{step.title}</h1>
                </Title>

                {documentToReactComponents(description, options)}
                {suggestedExercise && documentToReactComponents(suggestedExercise, options)}
            </ModalPageLayout>
        </ContentBoardStyled>
    )
}

const StepsNavigator = ({
    stepContent,
    stepNavigate,
}: {
    stepContent: ContentStepDTO
    stepNavigate: (navigation: NavigationType) => void
}) => {
    const { currentCourse } = useUserCourses()
    const { studyPlan, getModuleAndSection } = useStudyPlan(currentCourse?.courseId)
    const { getPrevAndNextStepId } = useStudyPlan(currentCourse?.courseId)
    const { moduleId, sectionId } = getModuleAndSection(stepContent._id)
    if (!studyPlan || !moduleId || !sectionId) return null
    const { prev, next } = getPrevAndNextStepId(moduleId, sectionId, stepContent._id, studyPlan)
    return (
        <StepNavigatorStyled>
            {moduleId && sectionId && prev && (
                <BtnSquare
                    mode="light"
                    onClick={event =>
                        stepNavigate({
                            event,
                            moduleId,
                            sectionId,
                            currentStepId: stepContent._id,
                            type: 'prev',
                        })
                    }
                    data-testid="back-btn"
                >
                    <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                </BtnSquare>
            )}

            {moduleId && sectionId && (
                <BtnSquare
                    mode="light"
                    onClick={event =>
                        stepNavigate({
                            event,
                            moduleId,
                            sectionId,
                            currentStepId: stepContent._id,
                            type: 'next',
                        })
                    }
                    data-testid="back-btn"
                    disabled={!next}
                >
                    <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </BtnSquare>
            )}
        </StepNavigatorStyled>
    )
}
const ContentBoardStyled = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 0 3rem;
    & > .content-board-header {
        padding-top: 1em;
        display: grid;
        grid-gap: 1em;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: ${defaultTheme.color.offWhite};
        & > nav {
            & > button {
                min-width: 200px;
                & > svg {
                    font-size: 15px;
                }
            }
        }
        & > section {
            z-index: 2;
            box-shadow: 0px 4px 4px 0px rgba(0, 55, 80, 0.15);
            border: none;
            & > .section-card:hover {
                background: transparent !important;
            }
            & .section-card {
                column-gap: 20px;

                & > .section-card-title {
                    grid-template-columns: auto 1fr auto;
                    & > h4 {
                        font-weight: 700;
                    }
                    & > h4,
                    .section-card-subtitle {
                        font-size: 1.43rem;
                    }
                    & > .underline-hover:hover {
                        text-decoration: none;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                & > .section-card {
                    padding: 1em 2em;
                    grid-template-areas: 'leftItem title items' !important;
                    grid-template-columns: [leftItem] auto [title] 1fr [items] auto !important;
                    grid-column-gap: 1em;
                    & > .section-card-title {
                        grid-template-columns: auto;
                        gap: 0.5em;
                        & > h4,
                        .section-card-subtitle {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .icon {
            font-size: 18px;
        }
        @media screen and (max-width: 768px) {
            .icon {
                font-size: 18px;
            }
        }
    }
`
const HeaderStyled = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-part {
        display: flex;
        align-items: center;
        gap: 1em;
        & > .content-board-button {
            border: none;
            background-color: transparent;
            & > .content-board-title {
                margin: 0;
                font-size: 1.14rem;
                font-weight: 600;
                color: ${defaultTheme.color.primary};
            }
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: normal;
        gap: 2em;
    }
`

const Title = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .content-title {
        margin: 0;
        font-size: 2em;
    }

    @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr;

        nav {
            order: -1;
            place-self: end;
        }
        & > .content-title {
            font-size: 1.7em;
        }
    }
`

const StepNavigatorStyled = styled.nav`
    display: flex;
    justify-content: space-evenly;
`
type NavigationType = {
    event: React.MouseEvent<HTMLButtonElement>
    currentStepId: string
    moduleId: string
    sectionId: string
    type: 'prev' | 'next'
}
