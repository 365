import styled from "styled-components";

export const BlurStyled = styled.div<{zIndex: number}>`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: ${({zIndex}) => zIndex};
    backdrop-filter: blur(8px);
    background: #00375095;
    transition: backdrop-filter 1 ease-in-out;
`