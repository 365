import styled from 'styled-components'
import { MyCourses } from './MyCourses'
import { useUser } from '../../../hooks/queries/useUser'
import { TermsAndConditions } from '../../../components/Modal/TermsAndConditions.modal'

export const Home = () => {
    const { showTermAndConditions } = useUser()

    if (showTermAndConditions) return <TermsAndConditions show={true} />

    return (
        <Section>
            <MyCourses />
        </Section>
    )
}

const Section = styled.section`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4em;
    padding-bottom: 4em;
`
